import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { CloseRounded, CloudUpload, Delete, Save } from "@mui/icons-material";
import {
  Button,
  CardMedia,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Avatar from "../../../../../assets/images/profile-avatar.jpg";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Alert, message } from "antd";

export type getUserRole = {
  id: string;
  name: string;
};
export type getUserById = {
  id: string;
  userName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  imageUrl: string;
  status: boolean;
};
export default function UpdateUser() {
  const queryParameters = new URLSearchParams(window.location.search);
  const { id } = useParams();

  const baseURL = "/User";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/`;
  const [userName, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [newPassword, setPass] = useState("");
  const [oldPassword, setOldPass] = useState("");
  const [email, setMail] = useState("");
  const [status, setStatus] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [roleId, setRole] = useState("");
  const [imageUrl, setImage] = useState("");
  const [datas, setData] = useState<getUserRole[] | null>();
  const [userDatas, setUserData] = useState<getUserById | null>();
  const [imageFile, setFile] = useState(null);
  const [errorPassword, setErrorPass] = useState("");
  const [errorUsername, setErrorUserName] = useState("");
  const [errorRolu, setErrorRol] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const [oldPass, setoldPass] = useState("");
  const [newPass, setnewPass] = useState("");

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllUserRole`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${baseURL}/getUserById?Id=${id}`)
        .then((response) => {
          setUserData(response.data);
          setUserName(response.data.userName);
          setFullName(response.data.fullName);
          setMail(response.data.email);
          setPhone(response.data.phoneNumber);
          setRole(response.data.roleId);
          setImage(response.data.imageUrl);
          setStatus(response.data.status);
        })
        .catch((error) => {
          message.error("Hata! Yükleme İşlemi Başarılı " + error.message);
        });
    }
    api();
  }, []);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button
              onClick={() => {
                setImage("");
                handleImageDelete();
              }}
            >
              Evet
            </Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };
  const updateUser = async () => {
    setErrorPass("");
    setErrorUserName("");
    setErrorRol("");
    setErrorDb("");

    const formData = new FormData();
    if (id != null) formData.append("id", id);
    formData.append("userName", userName);
    formData.append("FullName", fullName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("imageUrl", imageUrl);
    formData.append("roleId", roleId);
    formData.append("status", status);
    if (imageFile != null) formData.append("imageFile", imageFile);
    await axios
      .put(`${baseURL}/UpdateUser`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorPass(error.response.data.errors.Password);
          setErrorUserName(error.response.data.errors.UserName);
          setErrorRol(error.response.data.errors.RoleId);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
    setFile(null);
  };
  const changePassword = async () => {
    setOldPass("");
    setnewPass("");
    setErrorDb("");
    await axios
      .put(`${baseURL}/ChangePassword`, { id, oldPassword, newPassword })
      .then((response) => {
        message.error("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setoldPass(error.response.data.errors.OldPassword);
          setnewPass(error.response.data.errors.NewPassword);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setImage("");
  };
  const handleImageDelete = async () => {
    await axios
      .delete(`${imageBaseUrl}delete/${imageUrl}`)
      .then((response) => {
        message.error("Silme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Silme İşlemi Başarısız " + error.message);
      });

    setFile(null);
    updateUser();
    setShowDelete(false);
  };
  const [isShowDeleteModal, setShowDelete] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          justifyContent: "space-around",
          gap: "15px",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          {imageUrl ? (
            <Box>
              <Button onClick={() => setShowDelete(true)}>
                <Delete />
              </Button>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "250px",
              height: "280px",
            }}
          >
            {imageUrl && imageUrl !== "null" ? (
              <CardMedia
                component="img"
                loading="lazy"
                src={`${imageBaseUrl}get/${imageUrl}`}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                src={imageFile ? URL.createObjectURL(imageFile) : Avatar}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Fotoğraf
              <TextField
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            textAlign="center"
            color="black"
            sx={{
              alignItems: "Start",
            }}
          >
            Kullanıcı Bilgilerini Güncelleyin
          </Typography>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          <TextField
            label="Ad - Soyad"
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            sx={{ m: 1 }}
          />
          {errorUsername ? (
            <Alert
              type="error"
              message="Hata"
              description={`Kullanıcı adı ${errorUsername}`}
              closable
            />
          ) : null}
          <TextField
            label="Kullanıcı adı"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            sx={{ m: 1 }}
          />

          <TextField
            label="Email"
            value={email}
            onChange={(e) => {
              setMail(e.target.value);
            }}
            id="email"
            sx={{ m: 1 }}
          />
          <TextField
            label="Telefon"
            value={phoneNumber}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            id="phoneNumber"
            sx={{ m: 1 }}
            type="tel"
            inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={status}
            name="radio-buttons-group"
            onChange={(e) => setStatus(e.target.value)}
            sx={{ m: 1 }}
          >
            <FormControlLabel value="1" control={<Radio />} label="Aktif" />
            <FormControlLabel value="0" control={<Radio />} label="Bloke" />
          </RadioGroup>
          {errorRolu ? (
            <Alert
              type="error"
              message="Hata"
              description={`Rol ${errorRolu}`}
              closable
            />
          ) : null}
          <TextField
            label="Kullanıcı rolü"
            value={roleId}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            select
            id="roleId"
            sx={{ m: 1 }}
          >
            {datas
              ? datas.map((data) => {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })
              : null}
          </TextField>
          <Button
            type="submit"
            startIcon={<Save />}
            variant="contained"
            onClick={updateUser}
            sx={{ m: 1 }}
          >
            Güncelle
          </Button>
          {oldPass ? (
            <Alert
              type="error"
              message="Hata"
              description={`${oldPass}`}
              closable
            />
          ) : null}
          <TextField
            type="password"
            label="Eski Şifre"
            onChange={(e) => {
              setOldPass(e.target.value);
            }}
            id="password"
            sx={{ m: 1 }}
          />
          {newPass ? (
            <Alert
              type="error"
              message="Hata"
              description={`${newPass}`}
              closable
            />
          ) : null}
          <TextField
            type="password"
            label="Yeni Şifre"
            onChange={(e) => {
              setPass(e.target.value);
            }}
            id="password"
            sx={{ m: 1 }}
          />
          <Button
            type="submit"
            startIcon={<Save />}
            variant="contained"
            onClick={changePassword}
            sx={{ m: 1 }}
          >
            Değiştir
          </Button>
        </Box>
      </Box>
      {isShowDeleteModal ? (
        <Box
          sx={{
            display: "block",
            position: "fixed",
            zIndex: "100",
            paddingTop: "100px",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",

            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "40%",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "90%",
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
