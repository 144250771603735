import { Key, LoginRounded, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { Alert, message } from "antd";
const LoginPage = () => {
  const baseURL = "/User";

  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (Cookie.get("token")) navigate("/dashboard");
  }, []);

  const [errorPassword, setErrorPass] = useState("");
  const [errorUsername, setErrorUserName] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const AdminLogin = async () => {
    setErrorPass("");
    setErrorUserName("");
    setErrorDb("");
    await axios
      .post(`${baseURL}/Login`, {
        userName,
        password,
      })
      .then((response) => {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;
        const token = axios.defaults.headers.common["Authorization"];
        const id = response.data.id;
        Cookie.set("token", token, {
          expires: 10,
          secure: true,
          path: "/",
        });
        Cookie.set("userId", id, {
          expires: 100,
          secure: true,
          path: "/",
        });
        if (response.data.token) {
          message.success("İşlem Başarılı ");
          navigate("/dashboard");
        }
      })

      .catch((error) => {
        if (error.response.data.errors) {
          setErrorPass(error.response.data.errors.Password);
          setErrorUserName(error.response.data.errors.UserName);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "none", lg: "flex" },
          flexDirection: "column",

          width: "25%",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "95px",
            margin: "5%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box>
            <Typography textAlign="center">
              Giriş Yapmak İçin Kullanıcı Adınızı Giriniz
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              gap: "25px",
              width: "100%",
            }}
          >
            {errorUsername ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorUsername}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              label="Kullanici adi"
              onChange={(e) => setUsername(e.target.value)}
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            {errorPassword ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorPassword}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Sifre"
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Key />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              endIcon={<LoginRounded />}
              onClick={AdminLogin}
              sx={{ m: 1 }}
            >
              Giriş yap
            </Button>
            <Link to="/forgetedpassword">
              <Button sx={{ m: 1 }}>Şifremi unuttum</Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "flex", lg: "none" },
          flexDirection: "column",

          width: "40%",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "95px",
            margin: "5%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box>
            <Typography>Giriş Yapmak İçin Kullanıcı Adınızı Giriniz</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              gap: "25px",
              width: "100%",
            }}
          >
            {errorUsername ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorUsername}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              label="Kullanici adi"
              onChange={(e) => setUsername(e.target.value)}
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            {errorPassword ? (
              <Alert
                type="error"
                message="Hata"
                description={`Şifre ${errorPassword}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Sifre"
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Key />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              endIcon={<LoginRounded />}
              onClick={AdminLogin}
              sx={{ m: 1 }}
            >
              Giriş yap
            </Button>
            <Link to="/forgetedpassword">
              <Button sx={{ m: 1 }}>Şifremi unuttum</Button>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "95%",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "95px",
            margin: "1%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box>
            <Typography>Giriş Yapmak İçin Kullanıcı Adınızı Giriniz</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",

              gap: "25px",
              width: "100%",
            }}
          >
            {errorUsername ? (
              <Alert
                type="error"
                message="Hata"
                description={`Kullanıcı adı ${errorUsername}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              label="Kullanici adi"
              onChange={(e) => setUsername(e.target.value)}
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            {errorPassword ? (
              <Alert
                type="error"
                message="Hata"
                description={`Şifre ${errorPassword}`}
                closable
              />
            ) : null}
            <TextField
              size="small"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              label="Sifre"
              sx={{ m: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Key />
                  </InputAdornment>
                ),
              }}
            ></TextField>
          </Box>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              variant="contained"
              endIcon={<LoginRounded />}
              onClick={AdminLogin}
              sx={{ m: 1 }}
            >
              Giriş yap
            </Button>
            <Link to="/forgetedpassword">
              <Button sx={{ m: 1 }}>Şifremi unuttum</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginPage;
