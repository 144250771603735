import { Box, Typography } from "@mui/material";
import { message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

export type settingTypes = {
  id: string;
  logoUrl: string;
  brandName: string;
};
const Navbar = () => {
  const baseSettingURL = "/Setting";
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;
  const [settings, setSettings] = useState<settingTypes | null>(null);

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseSettingURL}/GetAllSetting`)
        .then((response) => {
          response.data.map((value: any) => setSettings(value));
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          gap: "25px",
          marginTop: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "25px",
            marginLeft: "20px",
          }}
        >
          <Box>
            {settings?.logoUrl ? (
              <img
                src={`${baseImageURL}/${settings?.logoUrl}`}
                loading="lazy"
                style={{ width: "45px", height: "45px" }}
              />
            ) : null}
          </Box>
          <Box>
            {" "}
            <Typography
              variant="h6"
              component="div"
              color="#1b2625"
              fontFamily="Knewave, system-ui"
              fontWeight="600"
              fontSize="25px"
            >
              {settings?.brandName}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Outlet />
    </Box>
  );
};

export default Navbar;
