import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Save } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { Alert, message } from "antd";
export default function CreatePage() {
  const baseURL = "/Page";
  const [pageName, setpageName] = useState("");
  const [pageContent, setpageContent] = useState("");
  const [titleSeo, setpageTitle] = useState("");
  const [descriptionSeo, setpageDescription] = useState("");

  const [errorCategory, setErrorCategory] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const createPage = () => {
    setErrorCategory("");
    setErrorDb("");
    axios
      .post(`${baseURL}/CreatePage`, {
        pageName,
        pageContent,
        titleSeo,
        descriptionSeo,
      })
      .then((response) => {
        message.success("Ekleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorCategory(error.response.data.errors.PageName);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Ekleme İşlemi Başarısız " + error.message);
      });
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    setpageContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
          margin: "20px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Siteniz İçin Sayfa oluşturun
          </Typography>
          <TextField
            label="Başlık (SEO)"
            onChange={(e) => setpageTitle(e.target.value)}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <label>Açıklama (SEO)</label>
          <TextArea
            placeholder="Açıklama (SEO)"
            onChange={(e) => setpageDescription(e.target.value)}
            id="outlined-start-adornment"
            style={{ margin: "5px" }}
          />
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          {errorCategory ? (
            <Alert
              type="error"
              message="Hata"
              description={`Sayfa adı ${errorCategory}`}
              closable
            />
          ) : null}
          <TextField
            label="Sayfa adı"
            onChange={(e) => setpageName(e.target.value)}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <Editor
            placeholder="İçerik oluşturun..."
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbarStyle={{
              border: "1px solid #ccc",
              borderRadius: "0.313rem",
            }}
            editorStyle={{
              padding: "0.625rem",
              fontFamily: "montserrat",
              border: "1px solid #ccc",
              marginTop: "1rem",
              backgroundColor: "#fff",
              borderRadius: "0.625rem",
              minHeight: "10rem",
            }}
          />
          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              onClick={createPage}
              sx={{ m: 1 }}
            >
              Kaydet
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
