import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CloudUpload, Save } from "@mui/icons-material";
import { Button, CardMedia, MenuItem, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import { Alert, message } from "antd";
import UseLocalStroge from "../../../Stroge/UseLocalStroge";

export type getAllCategory = {
  id: string;
  name: string;
};
export default function CreateProduct() {
  const baseURL = "/Product";
  const categoryBaseURL = "/Category";

  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState("");
  const [discountedPrice, setdiscountedPrice] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [categoryId, setcategoryId] = UseLocalStroge("categoryName", "");
  const [imageFile, setFile] = useState(null);
  const [userCategoryDatas, setCategoryData] = useState<
    getAllCategory[] | null
  >();

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${categoryBaseURL}/AdminGetAllCategory`)
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  const [errorCategory, setErrorCategory] = useState("");
  const [errorDb, setErrorDb] = useState("");

  const createProduct = () => {
    setErrorCategory("");
    setErrorDb("");
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("discountedPrice", discountedPrice);
    formData.append("imageUrl", imageUrl);
    formData.append("categoryId", categoryId);
    if (imageFile != null) formData.append("imageFile", imageFile);
    axios
      .post(`${baseURL}/CreateProduct`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Ekleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorCategory(error.response.data.errors.CategoryId);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Ekleme İşlemi Başarısız " + error.message);
      });
    setFile(null);
  };
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "250px",
              height: "280px",
            }}
          >
            {imageFile ? (
              <CardMedia
                component="img"
                loading="lazy"
                src={URL.createObjectURL(imageFile)}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                src={Avatar}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Fotoğraf
              <TextField
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Yeni Bir Ürün Ekleyin
          </Typography>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          {errorCategory ? (
            <Alert
              type="error"
              message="Hata"
              description={`Bölüm ${errorCategory}`}
              closable
            />
          ) : null}
          <TextField
            label="Bölüm Seçin"
            value={categoryId}
            onChange={(e) => {
              setcategoryId(e.target.value);
            }}
            select
            sx={{ m: 1 }}
          >
            {userCategoryDatas
              ? userCategoryDatas.map((data) => {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })
              : null}
          </TextField>
          <TextField
            label="Ürün adı"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextArea
            placeholder="Açıklama"
            id="outlined-start-adornment"
            onChange={(e) => {
              setdescription(e.target.value);
            }}
          />
          <Box>
            <TextField
              label="Fiyat"
              id="outlined-start-adornment"
              onChange={(e) => {
                setprice(e.target.value);
              }}
              sx={{ m: 1 }}
              type="number"
            />
            <TextField
              label="İndirimli Fiyat"
              onChange={(e) => {
                setdiscountedPrice(e.target.value);
              }}
              id="outlined-start-adornment"
              sx={{ m: 1 }}
              type="number"
            />
          </Box>

          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              onClick={createProduct}
              sx={{ m: 1 }}
            >
              Kaydet
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
