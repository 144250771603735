import { useState, MouseEvent, useEffect } from "react";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Facebook,
  Instagram,
  Mail,
  Phone,
  WhatsApp,
  X,
  YouTube,
} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Menu, MenuItem, MenuList, Button, CardMedia } from "@mui/material";
import Fade from "@mui/material/Fade";
import axios from "axios";
import { Link } from "react-router-dom";
import { message } from "antd";
import { TikTokOutlined } from "@ant-design/icons";

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // Override media queries injected by theme.mixins.toolbar
  "@media all": {
    minHeight: 130,
  },
}));

export type pageTypes = {
  id: string;
  pageName: string;
  pageUrl: string;
  orderNumber: number;
};
export type settingTypes = {
  id: string;
  logoUrl: string;
  brandName: string;
};
export type socialMediTypes = {
  id: string;
  twitter: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  youtube: string;
};
export type contactTypes = {
  id: string;
  email: string;
  phonNumber: string;
  whatsapp: string;
  fullAddress: string;
};

const Navbar = () => {
  const baseURL = "/Page";
  const baseSettingURL = "/Setting";
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;
  const baseContactURL = "/BusinessContact";
  const baseSocialURL = "/SocialMedia";
  const [anchorNav, setAnchorNav] = useState<null | HTMLElement>(null);
  const [pages, setPages] = useState<pageTypes[] | null>(null);
  const [settings, setSettings] = useState<settingTypes | null>(null);
  const [socials, setSocials] = useState<socialMediTypes | null>(null);
  const [contacts, setContacts] = useState<contactTypes | null>(null);

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllPage`)
        .then((response) => {
          setPages(response.data);
        })
        .catch((err) => {});
      await axios
        .get(`${baseSettingURL}/GetAllSetting`)
        .then((response) => {
          response.data.map((value: any) => setSettings(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseSocialURL}/GetAllSocialMedia`)
        .then((response) => {
          response.data.map((value: any) => setSocials(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseContactURL}/GetAllBusinessContactHome`)
        .then((response) => {
          response.data.map((value: any) => setContacts(value));
        })
        .catch((err) => {});
    }
    api();
  }, []);
  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorNav(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorNav(null);
  };

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#a10031",
        }}
      >
        <StyledToolbar
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                gap: "25px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                {settings?.logoUrl ? (
                  <CardMedia
                    component="img"
                    src={`${baseImageURL}/${settings?.logoUrl}`}
                    loading="lazy"
                    style={{ width: "45px", height: "45px" }}
                  />
                ) : null}
              </Box>
              <Typography
                variant="h6"
                component="div"
                sx={{ display: { xs: "none", md: "flex" } }}
                color="#FAF1E4"
                fontFamily="Knewave, system-ui"
                fontWeight="600"
                fontSize="25px"
              >
                {settings?.brandName}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginLeft: "5px",
                  alignItems: "start",
                }}
              >
                {contacts?.phonNumber ? (
                  <Box marginTop="5px">
                    <Link to={`tel:+9${contacts?.phonNumber}`}>
                      <Box
                        color="#FAF1E4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "5px",
                          alignItems: "start",
                        }}
                      >
                        <Phone style={{ color: "#FAF1E4", fontSize: "30px" }} />
                        {contacts?.phonNumber}
                      </Box>
                    </Link>
                  </Box>
                ) : null}
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  {contacts?.whatsapp ? (
                    <Link to={`https://wa.me/+9${contacts?.whatsapp}`}>
                      <Box
                        color="#FAF1E4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "15px",
                          alignItems: "start",
                        }}
                      >
                        <WhatsApp
                          style={{ color: "#FAF1E4", fontSize: "30px" }}
                        />
                      </Box>
                    </Link>
                  ) : null}
                  {contacts?.email ? (
                    <Link to={`mailto:${contacts?.email}`}>
                      <Box
                        color="#FAF1E4"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "15px",
                          alignItems: "start",
                        }}
                      >
                        {" "}
                        <Mail style={{ color: "#FAF1E4", fontSize: "30px" }} />
                      </Box>
                    </Link>
                  ) : null}
                  {socials?.facebook ? (
                    <Link to={socials.facebook}>
                      <Facebook
                        style={{ color: "#FAF1E4", fontSize: "30px" }}
                      />
                    </Link>
                  ) : null}
                  {socials?.twitter ? (
                    <Link to={socials.twitter}>
                      <X style={{ color: "#FAF1E4", fontSize: "30px" }} />
                    </Link>
                  ) : null}
                  {socials?.instagram ? (
                    <Link to={socials.instagram}>
                      <Instagram
                        style={{ color: "#FAF1E4", fontSize: "30px" }}
                      />
                    </Link>
                  ) : null}
                  {socials?.tiktok ? (
                    <Link to={socials.tiktok}>
                      <TikTokOutlined
                        style={{ color: "#FAF1E4", fontSize: "30px" }}
                      />
                    </Link>
                  ) : null}
                  {socials?.youtube ? (
                    <Link to={socials.youtube}>
                      <YouTube style={{ color: "#FAF1E4", fontSize: "30px" }} />
                    </Link>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ display: { xs: "none", md: "flex" }, width: "50px" }}
            ></Box>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Link to="/">
                <Button sx={{ color: "#FAF1E4" }}>Anasayfa</Button>
              </Link>
              {pages
                ?.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                .map((value, index) => {
                  return (
                    <Link
                      to={`/pages/${value.pageUrl}`}
                      reloadDocument
                      key={index}
                    >
                      <Button sx={{ color: "#FAF1E4" }}>
                        {value.pageName}
                      </Button>
                    </Link>
                  );
                })}
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton size="large" edge="start" onClick={openMenu}>
                <MenuIcon style={{ color: "#FAF1E4" }} />
              </IconButton>
              <Menu
                open={Boolean(anchorNav)}
                onClose={closeMenu}
                TransitionComponent={Fade}
                sx={{ display: { xs: "flex", md: "none" } }}
                PaperProps={{
                  elevation: 0,

                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

                    width: "100%",
                    zIndex: "100",
                    position: "relative",
                  },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
              >
                <MenuList
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <MenuItem>
                    <Link to="/" onClick={() => setAnchorNav(null)}>
                      <Button sx={{ color: "#1b2625" }}>Anasayfa</Button>
                    </Link>
                  </MenuItem>
                  {pages
                    ?.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                    .map((value, index) => {
                      return (
                        <MenuItem>
                          <Link
                            onClick={() => setAnchorNav(null)}
                            reloadDocument
                            to={`/pages/${value.pageUrl}`}
                            key={index}
                          >
                            <Button sx={{ color: "#1b2625" }}>
                              {value.pageName}
                            </Button>
                          </Link>
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </Menu>
            </Box>
            <Box
              sx={{
                gap: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                {settings?.logoUrl ? (
                  <CardMedia
                    component="img"
                    src={`${baseImageURL}/${settings?.logoUrl}`}
                    loading="lazy"
                    style={{ width: "45px", height: "45px" }}
                  />
                ) : null}
              </Box>
              <Typography
                variant="h6"
                component="div"
                sx={{ display: { xs: "flex", md: "none" } }}
                color="#FAF1E4"
                fontFamily="Knewave, system-ui"
                fontWeight="600"
                fontSize="25px"
              >
                {settings?.brandName}
              </Typography>
            </Box>
          </Box>
        </StyledToolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
