import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CloseRounded, CloudUpload, Delete, Save } from "@mui/icons-material";
import { Button, CardMedia, MenuItem, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useParams } from "react-router-dom";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import { Alert, message } from "antd";

export type getAllCategory = {
  id: string;
  name: string;
};
export default function UpdateProduct() {
  const baseURL = "/Product";
  const categoryBaseURL = "/Category";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/`;
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState("");
  const [discountedPrice, setdiscountedPrice] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [imageFile, setFile] = useState(null);
  const [userCategoryDatas, setCategoryData] = useState<
    getAllCategory[] | null
  >();

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${categoryBaseURL}/AdminGetAllCategory`)
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });

      await axios
        .get(`${baseURL}/AdminGetProductById?Id=${id}`)
        .then((response) => {
          setTitle(response.data.title);
          setdescription(response.data.description);
          setprice(response.data.price);
          setdiscountedPrice(response.data.discountedPrice);
          setimageUrl(response.data.imageUrl);
          setcategoryId(response.data.categoryId);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={handleImageDelete}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [errorCategory, setErrorCategory] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const updateProduct = () => {
    setErrorCategory("");
    setErrorDb("");
    const formData = new FormData();
    if (id != null) formData.append("id", id);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("discountedPrice", discountedPrice);
    formData.append("ImageUrl", imageUrl);
    formData.append("categoryId", categoryId);
    if (imageFile != null) formData.append("imageFile", imageFile);
    axios
      .put(`${baseURL}/UpdateProduct`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorCategory(error.response.data.errors.CategoryId);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setimageUrl("");
  };
  const handleImageDelete = async () => {
    await axios
      .delete(`${imageBaseUrl}delete/${imageUrl}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    setimageUrl("");
    setFile(null);
    updateProduct();
    setShowDelete(false);
  };
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          {imageUrl ? (
            <Box>
              <Button onClick={() => setShowDelete(true)}>
                <Delete />
              </Button>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "250px",
              height: "280px",
            }}
          >
            {imageUrl ? (
              <CardMedia
                component="img"
                loading="lazy"
                src={`${imageBaseUrl}get/${imageUrl}`}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                src={imageFile ? URL.createObjectURL(imageFile) : Avatar}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Fotoğraf
              <TextField
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Ürün Güncelleme
          </Typography>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          {errorCategory ? (
            <Alert
              type="error"
              message="Hata"
              description={`Bölüm ${errorCategory}`}
              closable
            />
          ) : null}
          <TextField
            label="Bölüm Seçin"
            onChange={(e) => {
              setcategoryId(e.target.value);
            }}
            value={categoryId}
            select
            sx={{ m: 1 }}
          >
            {userCategoryDatas
              ? userCategoryDatas.map((data) => {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })
              : null}
          </TextField>
          <TextField
            label="Ürün adı"
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextArea
            placeholder="Aciklama"
            value={description}
            id="outlined-start-adornment"
            onChange={(e) => {
              setdescription(e.target.value);
            }}
          />
          <Box>
            <TextField
              label="Fiyat"
              id="outlined-start-adornment"
              value={price}
              onChange={(e) => {
                setprice(e.target.value);
              }}
              sx={{ m: 1 }}
              type="number"
            />
            <TextField
              label="İndirimli Fiyat"
              value={discountedPrice}
              onChange={(e) => {
                setdiscountedPrice(e.target.value);
              }}
              id="outlined-start-adornment"
              sx={{ m: 1 }}
              type="number"
            />
          </Box>

          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              onClick={updateProduct}
              sx={{ m: 1 }}
            >
              Güncelle
            </Button>
          </Box>
        </div>
      </Box>
      {isShowDeleteModal ? (
        <Box
          sx={{
            display: "block",
            position: "fixed",
            zIndex: "100",
            paddingTop: "100px",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",

            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "40%",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "90%",
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
