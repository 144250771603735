import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Save } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { message } from "antd";
export default function SocialMediaAccaount() {
  const baseURL = "/SocialMedia";
  const [id, setId] = useState("");
  const [twitter, settwitter] = useState("");
  const [instagram, setinstagram] = useState("");
  const [facebook, setfacebook] = useState("");
  const [tiktok, settiktok] = useState("");
  const [youtube, setyoutube] = useState("");
  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllSocialMedia`)
        .then((response) => {
          response.data.map((value: any) => {
            setId(value.id);
            settwitter(value.twitter);
            setinstagram(value.instagram);
            setfacebook(value.facebook);
            settiktok(value.tiktok);
            setyoutube(value.youtube);
          });
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);
  const updateSocialMedia = async () => {
    await axios
      .put(`${baseURL}/UpdateSocialMedia`, {
        id,
        twitter,
        instagram,
        facebook,
        tiktok,
        youtube,
      })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  return (
    <Box>
      <Box
        sx={{
          gridTemplateColumns: "1fr 1fr 1fr",
          alignItems: "center",
          display: { xs: "block", md: "block", lg: "grid", xl: "grid" },
        }}
      >
        <Box></Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Sosyal Media Hesaplarınız
          </Typography>
          <TextField
            label="Twitter"
            onChange={(e) => settwitter(e.target.value)}
            value={twitter}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Instagram"
            onChange={(e) => setinstagram(e.target.value)}
            value={instagram}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Facebook"
            onChange={(e) => setfacebook(e.target.value)}
            value={facebook}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Tiktok"
            onChange={(e) => settiktok(e.target.value)}
            value={tiktok}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
            type="text"
          />
          <TextField
            label="Youtube"
            onChange={(e) => setyoutube(e.target.value)}
            value={youtube}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
            type="tel"
            inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
          />
          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              sx={{ m: 1 }}
              onClick={updateSocialMedia}
            >
              Kaydet
            </Button>
          </Box>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
}
