import React, { useEffect, useState } from "react";
import ImageSlider from "../../organisms/imageSlider/ImageSlider";
import MenuSection from "../../organisms/menuSection/MenuSection";
import Footer from "./Footer";
import AnasayfaYazisi from "./AnasayfaYazisi";
import axios from "axios";
import { Button, TextField, Box, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import { Helmet } from "react-helmet";
import { Alert, message } from "antd";
export type settingTypes = {
  id: string;
  isBanner: Boolean;
  isExplain: Boolean;
  isPopuler: Boolean;
  isPageList: Boolean;
  explain: string;
  titleSeo: string;
  descriptionSeo: string;
  logoUrl: string;
};
export type getDayTypes = {
  id: string;
  day: string;
  openingTime: string;
  closingTime: string;
  order: string;
};
const HomePage = () => {
  const baseURL = "/Setting";
  const baseCustomerURL = "/CustomerContact";

  const [setting, setSetting] = useState<settingTypes | null>(null);
  const [fullName, setFullname] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [phoneNumber, setNumber] = useState<string>();
  const [message1, setMessage] = useState<string>();
  const [days, setDays] = useState<getDayTypes[] | null>(null);
  const baseDayURL = "/WorkingDay";

  useEffect(() => {
    async function api() {
      await axios
        .get(
          `${baseURL}/GetAllSetting
        `
        )
        .then((response) => {
          response.data.map((value: any) => setSetting(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseDayURL}/GetAllWorkingDay`)
        .then((response) => {
          setDays(response.data);
        })
        .catch((err) => {});
    }
    api();
  }, []);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const sendMessage = async () => {
    setErrorEmail("");
    setErrorDb("");
    setErrorName("");
    setErrorPhone("");
    setErrorMessage("");
    var customerMessage = message1;
    await axios
      .post(
        `${baseCustomerURL}/CreateCustomerContact
    `,
        { fullName, email, phoneNumber, customerMessage }
      )
      .then((response) => {
        message.success("Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorEmail(error.response.data.errors.Email);
          setErrorName(error.response.data.errors.FullName);
          setErrorPhone(error.response.data.errors.PhoneNumber);
          setErrorMessage(error.response.data.errors.CustomerMessage);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Başarısız " + error.message);
      });
  };
  const workingDay = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",

          marginRight: "10px",
          borderRadius: "20px",
        }}
      >
        <Helmet>
          <title>{setting?.titleSeo}</title>
          <meta name="description" content={setting?.descriptionSeo} />
        </Helmet>
        <Typography
          variant="h6"
          align="center"
          sx={{
            color: "#C70039",
            textShadow: "3px 4px 7px rgba(199, 0, 57,0.8)",
          }}
        >
          Çalışma Günleri Ve Saatleri
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "35px",
            justifyContent: "center",
            backgroundColor: "#FFC300",
            color: "#C70039",
            padding: "10px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
            borderRadius: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              alignItems: "start",
              whiteSpace: "nowrap",
              textWrap: "nowrap",
              textAlign: "left",

              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {days
              ? days
                  .sort((a, b) => (a.order > b.order ? 1 : -1))
                  .map((value, index) => {
                    return <Box key={index}>{value.day}</Box>;
                  })
              : null}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              alignItems: "start",

              whiteSpace: "nowrap",
              textWrap: "nowrap",
              textAlign: "left",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {days
              ? days.map((value, index) => {
                  return <Box key={index}>{value.openingTime}</Box>;
                })
              : null}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              alignItems: "start",

              whiteSpace: "nowrap",
              textWrap: "nowrap",
              textAlign: "left",
              marginTop: "15px",
              marginBottom: "15px",
            }}
          >
            {days
              ? days.map((value, index) => {
                  return <Box key={index}>{value.closingTime}</Box>;
                })
              : null}
          </Box>
        </Box>
      </Box>
    );
  };
  const customerContact = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          backgroundColor: "#F2F1EB",
          padding: "10px",
          borderRadius: "10px",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
      >
        <TextField
          label="Adınız ve Soyadınız"
          onChange={(e) => setFullname(e.target.value)}
        ></TextField>
        <TextField
          label="Telefonunuz"
          onChange={(e) => setNumber(e.target.value)}
        ></TextField>
        {errorEmail ? (
          <Alert
            type="error"
            message="Hata"
            description={`${errorEmail}`}
            closable
          />
        ) : null}
        <TextField
          label="Email adresiniz"
          onChange={(e) => setEmail(e.target.value)}
        ></TextField>
        <TextArea
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Mesajınız..."
          style={{ height: "255px", background: "transparent" }}
        ></TextArea>
        {errorName || errorMessage || errorPhone ? (
          <Alert
            type="error"
            message="Hata"
            description={`Tüm alanlar doldurulmalı`}
            closable
          />
        ) : null}
        {errorDb ? (
          <Alert
            type="error"
            message="Hata"
            description={`${errorDb}`}
            closable
          />
        ) : null}
        <Button
          onClick={sendMessage}
          variant="contained"
          color="primary"
          sx={{ position: "sticky" }}
          size="large"
          style={{ width: "150px" }}
        >
          Gönder
        </Button>
      </Box>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
      <div>
        <ImageSlider isBanner={setting?.isBanner} />
      </div>

      <Box>
        <AnasayfaYazisi
          isExplain={setting?.isExplain}
          explain={setting?.explain}
        />
      </Box>

      <Box bgcolor="#FBF6F0">
        <MenuSection
          isList={setting?.isPageList}
          isPopuler={setting?.isPopuler}
        />
      </Box>
      <Box
        sx={{
          marginTop: "110px",
          marginLeft: "10%",
          marginRight: "10%",
          gridTemplateColumns: "1fr 1fr",
          display: { xs: "block", sm: "block", md: "block", lg: "grid" },
        }}
      >
        <Box>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            {workingDay()}
          </Box>
        </Box>

        <Box sx={{ marginTop: "10px" }}>
          {" "}
          <Box fontSize="25px">Biz Sizi Arayalım</Box>
          {customerContact()}
        </Box>
      </Box>
      <div style={{ marginTop: "80px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
