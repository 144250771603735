import { MoonFilled, SunFilled } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

type props = {
  darkTheme: any;
  toggleTheme: (event: React.MouseEvent<HTMLElement>) => void;
  style: React.CSSProperties;
};
const ToggleThemeButton = (props: props) => {
  return (
    <>
      <Button onClick={(event) => props.toggleTheme(event)} style={props.style}>
        {props.darkTheme ? <SunFilled /> : <MoonFilled />}
      </Button>
    </>
  );
};

export default ToggleThemeButton;
