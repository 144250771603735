import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Save } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { message } from "antd";
export type workingDay = {
  id: string;
  day: string;
  openingTime: string;
  closingTime: string;
  order: string;
};
export default function WorkingDay() {
  const baseURL = "/WorkingDay";
  const [id, setId] = useState("");
  const [entities, setEntities] = useState<workingDay[] | null>([]);
  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllWorkingDay`)
        .then((response) => {
          setEntities(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);
  const updateWorkingDay = async () => {
    await axios
      .put(`${baseURL}/UpdateWorkingDays`, entities)
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  const handleInputChange = (event: any, entityId: any, property: any) => {
    const updatedEntities = entities?.map((entity) => {
      if (entity.id === entityId) {
        return { ...entity, [property]: event.target.value };
      }
      return entity;
    });

    setEntities(updatedEntities ? updatedEntities : null);
  };
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "space-around",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Haftalık Çalısma Saatleriniz
          </Typography>
          {entities
            ?.sort((a, b) => (a.order > b.order ? 1 : -1))
            .map((entity) => (
              <Box
                sx={{
                  gridTemplateColumns: "0.5fr 1fr 1fr",
                  display: { xs: "block", sm: "block", md: "grid" },
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="500"
                  fontSize="25px"
                  component="div"
                  color="black"
                  textAlign="center"
                  sx={{
                    alignItems: "Start",
                  }}
                >
                  {entity.day}
                </Typography>
                <Box>
                  <TextField
                    label="Açılış"
                    sx={{ m: 1 }}
                    value={entity.openingTime}
                    onChange={(e) =>
                      handleInputChange(e, entity.id, "openingTime")
                    }
                  />
                  <TextField
                    label="Kapanış"
                    value={entity.closingTime}
                    onChange={(e) =>
                      handleInputChange(e, entity.id, "closingTime")
                    }
                    id="outlined-start-adornment"
                    sx={{ m: 1 }}
                  />
                </Box>
              </Box>
            ))}

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              sx={{ m: 1 }}
              onClick={updateWorkingDay}
            >
              Kaydet
            </Button>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
