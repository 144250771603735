import React from "react";
import axios from "axios";
import MainPage from "./components/pages/MainPage";
import AdminMainPage from "./components/pages/Admin/AdminMainPage";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./components/pages/Admin/LoginPage";
import UserPage from "./components/pages/Admin/UserPage";
import UpdatePage from "./components/templates/admin/BusinessTemplates/Page/Update/UpdatePage";
import CreatePage from "./components/templates/admin/BusinessTemplates/Page/Create/CreatePage";
import PageList from "./components/templates/admin/BusinessTemplates/Page/List/PageList";
import CreateProduct from "./components/templates/admin/ProductTemplate/Create/CreateProduct";
import UpdateProduct from "./components/templates/admin/ProductTemplate/Update/UpdateProduct";
import ListProduct from "./components/templates/admin/ProductTemplate/List/ProductList";
import ListCategory from "./components/templates/admin/CategoryTemplate/List/ListCategory";
import CustomerContactView from "./components/templates/admin/CustomerContactTemplate/List/CustomerContactView";
import WorkingDay from "./components/templates/admin/BusinessTemplates/WorkingDay/Update/WorkingDay";
import SocialMediaAccaount from "./components/templates/admin/BusinessTemplates/SocialMedia/Update/SocialMedia";
import WebSetting from "./components/templates/admin/BusinessTemplates/Setting/WebSetting";
import UpdateBusinessContact from "./components/templates/admin/BusinessTemplates/BusinessContact/Update/UpdateBusinessContact";
import UpdateUser from "./components/templates/admin/UserTemplate/Update/UpdateUser";
import CreateUser from "./components/templates/admin/UserTemplate/Create/CreateUser";
import ForgetPassword from "./components/pages/Admin/ForgetPassword";
import InputOtpCode from "./components/pages/Admin/InputOtpCode";
import ChangePassword from "./components/pages/Admin/ChangePassword";
import Cookie from "js-cookie";
import HomePage from "./components/templates/UserTemplate/HomePage";
import PagesDynamic from "./components/templates/UserTemplate/PagesDynamic";
import Dashboard from "./components/templates/admin/Dashboard";
import Navbar from "./components/pages/Admin/Navbar";
import Profile from "./components/pages/Admin/Profile";
import NotFoundPage from "./components/templates/NotFoundPage";
import UpdateBulkPrice from "./components/templates/admin/ProductTemplate/Update/UpdateBulkPrice";

function App() {
  axios.defaults.baseURL = "https://apirest.pendikpide.com/api";
  axios.defaults.headers.common["Authorization"] = Cookie.get("token");
  return (
    <div className="App">
      <Routes>
        <Route path="*" element={<NotFoundPage />} />
        <Route element={<AdminMainPage />}>
          <Route path="/user" element={<UserPage />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route index path="/dashboard" element={<Dashboard />} />
          <Route path="/CreateUser" element={<CreateUser />} />
          <Route path="/userupdate/:id" element={<UpdateUser />} />
          <Route path="/contact" element={<UpdateBusinessContact />} />
          <Route path="/setting" element={<WebSetting />}></Route>
          <Route path="/socialmedia" element={<SocialMediaAccaount />} />
          <Route path="/workingday" element={<WorkingDay />} />
          <Route path="/customer-messages" element={<CustomerContactView />} />
          <Route path="/menu-section" element={<ListCategory />} />
          <Route path="/product" element={<ListProduct />}></Route>
          <Route path="/update-product/:id" element={<UpdateProduct />} />
          <Route path="/create-product" element={<CreateProduct />} />
          <Route path="/pagelist" element={<PageList />} />
          <Route path="/create-page" element={<CreatePage />} />
          <Route
            path="/update-bulk-product-price"
            element={<UpdateBulkPrice />}
          />
          <Route path="/update-page/:id" element={<UpdatePage />} />
        </Route>
        <Route element={<MainPage />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/pages/:name" element={<PagesDynamic />} />
        </Route>

        <Route element={<Navbar />}>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgetedpassword" element={<ForgetPassword />} />
          <Route path="/input-otpcode/:email" element={<InputOtpCode />} />
          <Route
            path="/changepassword/:email/:otp"
            element={<ChangePassword />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
