import { Box, CardMedia } from "@mui/material";
import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  Mail,
  Phone,
  WhatsApp,
  X,
  YouTube,
} from "@mui/icons-material";
import { TikTokOutlined } from "@ant-design/icons";

export type pageTypes = {
  id: string;
  pageName: string;
  pageUrl: string;
  orderNumber: number;
};
export type settingTypes = {
  id: string;
  logoUrl: string;
  brandName: string;
};
export type socialMediTypes = {
  id: string;
  twitter: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  youtube: string;
};
export type contactTypes = {
  id: string;
  email: string;
  phonNumber: string;
  whatsapp: string;
  fullAddress: string;
};
const Footer = () => {
  const baseURL = "/Page";
  const baseSettingURL = "/Setting";
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;
  const baseSocialURL = "/SocialMedia";
  const baseContactURL = "/BusinessContact";
  const [pages, setPages] = useState<pageTypes[] | null>(null);
  const [settings, setSettings] = useState<settingTypes | null>(null);
  const [socials, setSocials] = useState<socialMediTypes | null>(null);
  const [contacts, setContacts] = useState<contactTypes | null>(null);

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllPage`)
        .then((response) => {
          setPages(response.data);
        })
        .catch((err) => {});
      await axios
        .get(`${baseSettingURL}/GetAllSetting`)
        .then((response) => {
          response.data.map((value: any) => setSettings(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseSocialURL}/GetAllSocialMedia`)
        .then((response) => {
          response.data.map((value: any) => setSocials(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseContactURL}/GetAllBusinessContactHome`)
        .then((response) => {
          response.data.map((value: any) => setContacts(value));
        })
        .catch((err) => {});
    }
    api();
  }, []);
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "none", sm: "none", md: "grid" },
          gridTemplateColumns: "1fr",
          alignItems: "center",
          justifyContent: "center",
          gap: "35px",

          backgroundColor: "#900C3F",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #dadece",
            marginLeft: "25px",
            marginRight: "25px",
            marginBottom: "35px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
              marginTop: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "35px" }}>
              {settings?.logoUrl ? (
                <CardMedia
                  component="img"
                  src={`${baseImageURL}/${settings?.logoUrl}`}
                  loading="lazy"
                  style={{ width: "45px", height: "45px" }}
                />
              ) : null}
              <Typography
                variant="h6"
                component="div"
                fontFamily="Knewave, system-ui"
                fontWeight="600"
                fontSize="25px"
              >
                {settings?.brandName}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
            }}
          >
            {socials?.facebook ? (
              <Link to={socials.facebook}>
                <Facebook style={{ color: "white", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.twitter ? (
              <Link to={socials.twitter}>
                <X style={{ color: "white", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.instagram ? (
              <Link to={socials.instagram}>
                <Instagram style={{ color: "white", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.tiktok ? (
              <Link to={socials.tiktok}>
                <TikTokOutlined style={{ color: "white", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.youtube ? (
              <Link to={socials.youtube}>
                <YouTube style={{ color: "white", fontSize: "30px" }} />
              </Link>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              alignItems: "start",
            }}
          >
            <Typography>Bize ulaşın</Typography>
            {contacts?.phonNumber ? (
              <Link to={`tel:+9${contacts?.phonNumber}`}>
                <Box
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                    alignItems: "start",
                  }}
                >
                  <Phone />
                  {contacts?.phonNumber}
                </Box>
              </Link>
            ) : null}
            {contacts?.whatsapp ? (
              <Link to={`https://wa.me/+9${contacts?.whatsapp}`}>
                <Box
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                    alignItems: "start",
                  }}
                >
                  <WhatsApp />
                  {contacts?.whatsapp}
                </Box>
              </Link>
            ) : null}
            {contacts?.email ? (
              <Link to={`mailto:${contacts?.email}`}>
                <Box
                  color="white"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "15px",
                    alignItems: "start",
                  }}
                >
                  {" "}
                  <Mail />
                  {contacts?.email}
                </Box>
              </Link>
            ) : null}
          </Box>
          <Box sx={{ whiteSpace: "pre-wrap" }}>
            <Typography sx={{ color: "#ccc" }} variant="h5">
              Adres
            </Typography>
            <address style={{ fontStyle: "italic", fontWeight: "500" }}>
              {contacts?.fullAddress}
            </address>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: "35px",
              display: { xs: "block", sm: "block", md: "flex" },
            }}
          >
            <Link to="/">
              <Button sx={{ color: "white" }}>Anasayfa</Button>
            </Link>
            {pages
              ?.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
              .map((value, index) => {
                return (
                  <Link to={`/pages/${value.pageUrl}`} key={index}>
                    <Button sx={{ color: "white" }}>{value.pageName}</Button>
                  </Link>
                );
              })}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", sm: "grid", md: "none" },
          flexDirection: "column",
          backgroundColor: "#900C3F",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1px solid #dadece",
            marginBottom: "25px",
            marginLeft: "35px",
            marginRight: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "25px",
              marginTop: "10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "35px" }}>
              {settings?.logoUrl ? (
                <CardMedia
                  component="img"
                  src={`${baseImageURL}/${settings?.logoUrl}`}
                  loading="lazy"
                  style={{ width: "45px", height: "45px" }}
                />
              ) : null}
              <Typography
                variant="h6"
                component="div"
                color="#748991"
                fontFamily="Knewave, system-ui"
                fontWeight="600"
                fontSize="25px"
                sx={{ color: "#fff" }}
              >
                {settings?.brandName}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginTop: "50px",
              justifyContent: "center",
            }}
          >
            {socials?.facebook ? (
              <Link to={socials.facebook}>
                <Facebook style={{ color: "#fff", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.twitter ? (
              <Link to={socials.twitter}>
                <X style={{ color: "#fff", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.instagram ? (
              <Link to={socials.instagram}>
                <Instagram style={{ color: "#fff", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.tiktok ? (
              <Link to={socials.tiktok}>
                <TikTokOutlined style={{ color: "#fff", fontSize: "30px" }} />
              </Link>
            ) : null}
            {socials?.youtube ? (
              <Link to={socials.youtube}>
                <YouTube style={{ color: "#fff", fontSize: "30px" }} />
              </Link>
            ) : null}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
              marginLeft: "35px",
              alignItems: "start",
            }}
          >
            <Typography>Bize ulaşın</Typography>
            <Link to={`tel:+9${contacts?.phonNumber}`}>
              <Box
                color="#fff"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  alignItems: "start",
                }}
              >
                <Phone />
                {contacts?.phonNumber}
              </Box>
            </Link>
            <Link to={`https://wa.me/+9${contacts?.whatsapp}`}>
              <Box
                color="#fff"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  alignItems: "start",
                }}
              >
                <WhatsApp />
                {contacts?.whatsapp}
              </Box>
            </Link>
            <Link to={`mailto:${contacts?.email}`}>
              <Box
                color="#fff"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "15px",
                  alignItems: "start",
                }}
              >
                {" "}
                <Mail />
                {contacts?.email}
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              whiteSpace: "pre-wrap",
              marginLeft: "35px",
              marginTop: "25px",
            }}
          >
            <Typography sx={{ color: "#ccc" }} variant="h5">
              Adres
            </Typography>
            <address style={{ fontStyle: "italic", fontWeight: "500" }}>
              {contacts?.fullAddress}
            </address>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: "35px",
              display: { xs: "block", sm: "block", md: "flex" },
              marginLeft: "5px",
              textAlign: "center",
            }}
          >
            <Link to="/">
              <Button sx={{ color: "#fff" }}>Anasayfa</Button>
            </Link>
            {pages
              ?.sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
              .map((value, index) => {
                return (
                  <Link to={`/pages/${value.pageUrl}`} key={index}>
                    <Button sx={{ color: "#fff" }}>{value.pageName}</Button>
                  </Link>
                );
              })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
