import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { CloudUpload, Save } from "@mui/icons-material";
import { Button, CardMedia, Typography } from "@mui/material";
import Avatar from "../../../../../assets/images/profile-avatar.jpg";
import axios from "axios";
import { Alert, message } from "antd";

export type getUserRole = {
  id: string;
  name: string;
};

export default function CreateUser() {
  const baseURL = "/User";

  const [userName, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPass] = useState("");
  const [email, setMail] = useState("");
  const [phoneNumber, setPhone] = useState("");
  const [roleId, setRole] = useState("");
  const [imageFile, setFile] = useState(null);

  const [datas, setData] = useState<getUserRole[] | null>();

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetAllUserRole`)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {});
    }
    getApi();
  }, []);
  const createUser = async () => {
    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("FullName", fullName);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("phoneNumber", phoneNumber);
    formData.append("roleId", roleId);
    if (imageFile != null) formData.append("imageFile", imageFile);
    setErrorPass("");
    setErrorUserName("");
    setErrorRol("");
    setErrorDb("");
    await axios
      .post(`${baseURL}/CreateUser`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Ekleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorPass(error.response.data.errors.Password);
          setErrorUserName(error.response.data.errors.UserName);
          setErrorRol(error.response.data.errors.RoleId);
        }
        if (error) setErrorDb(error.response.data.error);

        message.error("Hata! Ekleme İşlemi Başarısız " + error.message);
      });
    setFile(null);
  };

  const [errorPassword, setErrorPass] = useState("");
  const [errorUsername, setErrorUserName] = useState("");
  const [errorRolu, setErrorRol] = useState("");
  const [errorDb, setErrorDb] = useState("");
  /*const onChangeHandler = (e: any) => {
    setUserData((prev: any) => {
      let helper = { ...prev };
      helper[`${e.target.id}`] = e.target.value;
      return helper;
    });
    console.log(userDatas);
  };*/

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };
  return (
    <Box>
      <Box
        sx={{
          gridTemplateColumns: "2fr 2fr 1fr",
          justifyContent: "space-around",
          gap: "15px",
          display: { xs: "block", md: "block", lg: "grid", xl: "grid" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "250px",
              height: "280px",
            }}
          >
            {imageFile ? (
              <CardMedia
                component="img"
                loading="lazy"
                src={URL.createObjectURL(imageFile)}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                src={Avatar}
                style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUpload />}
            >
              Fotoğraf
              <TextField
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            textAlign="center"
            component="div"
            color="black"
            sx={{
              alignItems: "Start",
            }}
          >
            Yeni Bir Kullanıcı Oluşturun
          </Typography>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          <TextField
            label="Ad - Soyad"
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            sx={{ m: 1 }}
          />
          {errorUsername ? (
            <Alert
              type="error"
              message="Hata"
              description={`Kullanıcı adı ${errorUsername}`}
              closable
            />
          ) : null}
          <TextField
            label="Kullanıcı adı"
            onChange={(e) => {
              setUserName(e.target.value);
            }}
            sx={{ m: 1 }}
          />
          {errorPassword ? (
            <Alert
              type="error"
              message="Hata"
              description={`Şifre ${errorPassword}`}
              closable
            />
          ) : null}
          <TextField
            label="Şifre"
            type="password"
            onChange={(e) => {
              setPass(e.target.value);
            }}
            id="password"
            sx={{ m: 1 }}
          />
          <TextField
            label="Email"
            onChange={(e) => {
              setMail(e.target.value);
            }}
            id="email"
            sx={{ m: 1 }}
          />
          <TextField
            label="Telefon"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            id="phoneNumber"
            sx={{ m: 1 }}
            type="tel"
            inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
          />
          {errorRolu ? (
            <Alert
              type="error"
              message="Hata"
              description={`Rol ${errorRolu}`}
              closable
            />
          ) : null}
          <TextField
            label="Kullanıcı rolü"
            onChange={(e) => {
              setRole(e.target.value);
            }}
            select
            id="roleId"
            sx={{ m: 1 }}
          >
            {datas
              ? datas.map((data) => {
                  return (
                    <MenuItem key={data.id} value={data.id}>
                      {data.name}
                    </MenuItem>
                  );
                })
              : null}
          </TextField>
          <Box>
            <Button
              type="submit"
              sx={{ m: 1 }}
              startIcon={<Save />}
              variant="contained"
              onClick={createUser}
            >
              Kaydet
            </Button>
          </Box>
        </div>
        <Box></Box>
      </Box>
    </Box>
  );
}
