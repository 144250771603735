import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import { CloseRounded, DeleteForever, Update } from "@mui/icons-material";
import { Box, Button, CardMedia, TextField, Typography } from "@mui/material";
import axios from "axios";
import Avatar from "../../../../assets/images/profile-avatar.jpg";
import { Flex, Spin, message } from "antd";
export type getAllUser = {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  imageUrl: string;
  createdAt: string;
  status: string;
  fullName: string;
};
export type getUserRole = {
  id: string;
  name: string;
};

const CustomDataTable = () => {
  const baseURL = "/User";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userDatas, setUserData] = useState<getAllUser[] | null>();
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const [roles, setUserRole] = useState<getUserRole[] | null>(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetAllUserRole`)
        .then((response) => {
          setUserRole(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${baseURL}/getAllUser`)
        .then((response) => {
          setUserData(response.data);
          setRecords(response.data);
          setLoading(false);
        })
        .catch((err) => {
          message.error("Veriler Yüklenemedi! " + err.message);
        });
    }
    getApi();
  }, []);

  const [idDelete, setIdDelete] = useState("");

  const deleteUser = async () => {
    await axios
      .delete(`${baseURL}/DeleteUser?Id=${idDelete}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Silme İşlemi Başarısız! " + err.message);
      });
    const newData = userDatas
      ? userDatas.filter((row) => row.id !== idDelete)
      : null;
    setUserData(newData);
    setRecords(newData);
    setShowDelete(false);
  };
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deleteUser}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [records, setRecords] = useState(userDatas);
  const handlefilter = (event: any) => {
    const newData = userDatas
      ? userDatas.filter((row) => {
          return row.userName
            .toLocaleLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      : null;
    setRecords(newData);
  };
  const columns = [
    {
      name: "Resim",
      cell: (row: any) => (
        <Box>
          {row.imageUrl ? (
            <CardMedia
              component="img"
              src={`${imageBaseUrl}${row.imageUrl}`}
              style={{ width: "60px", height: "60px", borderRadius: "50%" }}
              loading="lazy"
            />
          ) : (
            <CardMedia
              component="img"
              src={Avatar}
              style={{ width: "60px", height: "60px", borderRadius: "50%" }}
              loading="lazy"
            />
          )}
        </Box>
      ),
    },
    {
      name: "Ad - Soyad",
      selector: (row: { fullName: string }) => row.fullName,
      sortable: true,
    },
    {
      name: "Kullanıcı adı",
      selector: (row: { userName: string }) => row.userName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: { email: string }) => row.email,
      sortable: true,
    },
    {
      name: "Telefon",
      selector: (row: { phoneNumber: string }) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Durum",
      cell: (row: any) => (
        <>
          {row.status === "1" ? (
            <Box
              sx={{
                backgroundColor: "#11bb11",
                padding: "6px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              Aktif
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#ff1111",
                padding: "6px",
                borderRadius: "5px",
                color: "white",
              }}
            >
              Bloke
            </Box>
          )}
        </>
      ),
    },
    {
      name: "Rol",
      cell: (row: any) => (
        <>
          {roles?.map((valueRol: any) => {
            if (row.roleId === valueRol.id) {
              return <Box>{valueRol.name}</Box>;
            }
          })}
        </>
      ),
      sortable: true,
    },

    {
      name: "Eklenme Tarihi",
      selector: (row: { createdAt: string }) => row.createdAt,
      sortable: true,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <Box>
          <Button
            onClick={() => {
              setIdDelete(row.id);
              setShowDelete(true);
            }}
          >
            <DeleteForever />
          </Button>
          <Link to={`/userupdate/${row.id}`}>
            <Button>
              <Update />
            </Button>
          </Link>
        </Box>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            backgroundColor: "white",
          }}
        >
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </Box>
      ) : (
        <>
          <div className="dataTable">
            <div>
              <TextField type="text" onChange={handlefilter} label="Ara..." />
            </div>

            {records ? (
              <DataTable
                columns={columns}
                data={records}
                responsive
                highlightOnHover
                dense
                persistTableHead
                pointerOnHover
                striped
                title="Kullanıcı Bilgileri Listesi"
                fixedHeader
                pagination
              />
            ) : null}
            {isShowDeleteModal ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "100",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "40%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "90%",
                    display: { xs: "flex", sm: "flex", md: "none" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>
              </Box>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default CustomDataTable;
