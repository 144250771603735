import React from "react";
import { Link } from "react-router-dom";
import "./NotFoundPage.css";
import { Box } from "@mui/material";
const NotFoundPage = () => {
  return (
    <Box>
      <h1 className="textNotFound">404 Sayfa Bulunamadı</h1>
      <p className="zoom-area"></p>
      <Box className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </Box>
      <div className="link-container">
        <Link to="/" className="more-link">
          Anasayfa
        </Link>
      </div>
    </Box>
  );
};

export default NotFoundPage;
