import React from "react";
import Usertemplate from "../../templates/admin/UserTemplate/List/UserList";

function UserPage() {
  return (
    <div>
      <Usertemplate />
    </div>
  );
}

export default UserPage;
