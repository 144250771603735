import React, { useEffect, useState } from "react";
import { Carousel, message } from "antd";
import axios from "axios";
import { Box, CardMedia } from "@mui/material";

export type sliderType = {
  id: string;
  imageUrl: string;
};

type banner = {
  isBanner: Boolean | undefined;
};
const ImageSlider = (prop: banner) => {
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;
  const baseSliderName = "/Banner";
  const [sliderNames, setSliderName] = useState<sliderType[] | null>(null);
  const { isBanner } = prop;
  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseSliderName}/GetAllBanner`)
        .then((response) => {
          setSliderName(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);
  return (
    <>
      {isBanner ? (
        <Carousel
          autoplay
          dots
          speed={1500}
          style={{
            height: "550px",
          }}
        >
          {sliderNames?.map((value, index) => {
            return (
              <Box>
                <CardMedia
                  loading="lazy"
                  src={`${baseImageURL}/${value.imageUrl}`}
                  component="img"
                  height={550}
                />
              </Box>
            );
          })}
        </Carousel>
      ) : null}
    </>
  );
};

export default ImageSlider;
