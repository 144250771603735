import { Box, Button, TextField, Typography } from "@mui/material";
import { Alert, message } from "antd";

import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const baseURL = "/User";
  const [receiverEmail, setEmail] = useState("");
  const navigate = useNavigate();
  const [errorEmail, setErrorEmail] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const sendOtpCode = async () => {
    setErrorEmail("");
    setErrorDb("");
    await axios
      .post(`${baseURL}/ForgetPassword`, {
        receiverEmail,
      })
      .then((response) => {
        message.success("İşlem Başarılı ");
        if (response.data == true) navigate(`/input-otpcode/${receiverEmail}`);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorEmail(error.response.data.errors.ReceiverEmail);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };

  return (
    <div>
      <Box
        sx={{
          marginTop: "55px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "40%",

            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <Typography textAlign="center">
                Doğrulama kodu almak için lütfen emailinizi giriniz
              </Typography>
            </Box>
            {errorEmail ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorEmail}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                size="small"
                label="Email"
                sx={{ m: 1, width: "35ch" }}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Kod Gönder
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "60%",
            flexDirection: "column",
            display: { xs: "none", sm: "none", md: "flex", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography textAlign="center">
                Doğrulama kodu almak için lütfen emailinizi giriniz
              </Typography>
            </Box>
            {errorEmail ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorEmail}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                size="small"
                label="Email"
                sx={{ m: 1, width: "35ch" }}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Kod Gönder
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "95%",
            flexDirection: "column",
            display: { xs: "column", sm: "column", md: "none", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography textAlign="center">
                Doğrulama kodu almak için lütfen emailinizi giriniz
              </Typography>
            </Box>
            <Box>
              {errorEmail ? (
                <Alert
                  type="error"
                  message="Hata"
                  description={`${errorEmail}`}
                  closable
                />
              ) : null}
              <TextField
                size="small"
                label="Email"
                sx={{ m: 1, width: "35ch" }}
                onChange={(e) => setErrorEmail(e.target.value)}
              ></TextField>
            </Box>
            <Box>
              {errorDb ? (
                <Alert
                  type="error"
                  message="Hata"
                  description={`${errorDb}`}
                  closable
                />
              ) : null}
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Kod Gönder
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ForgetPassword;
