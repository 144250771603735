import React from "react";
import SidebarComp from "../../organisms/sidebar/sidebar";

const AdminMain = () => {
  return (
    <div>
      <SidebarComp />
    </div>
  );
};

export default AdminMain;
