import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import {
  AddCircle,
  CloseRounded,
  DeleteForever,
  Star,
  StarBorderOutlined,
  Update,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Ghost } from "lucide-react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  TextField,
  CardMedia,
} from "@mui/material";
import axios from "axios";

import { message } from "antd";
import UseLocalStroge from "../../../Stroge/UseLocalStroge";
export type getAllCategory = {
  id: string;
  name: string;
};
export type getProduct = {
  id: string;
  title: string;
  description: string;
  price: string;
  discountedPrice: string;
  imageUrl: string;
  categoryId: string;
  isActive: boolean;
  isStarred: boolean;
  isGhost: boolean;
};
const ListProduct = () => {
  const baseURL = "/Product";
  const categoryBaseURL = "/Category";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userCategoryDatas, setCategoryData] = useState<
    getAllCategory[] | null
  >();
  const [firstCategory, setFirstCat] = UseLocalStroge("categoryName", "");
  const [products, setProduct] = useState<getProduct[] | null>();

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${categoryBaseURL}/AdminGetAllCategory`)
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((err) => {});
    }
    getApi();
  }, []);
  const [idDelete, setIdDelete] = useState("");
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediginizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deleteProduct}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayir</Button>
          </Box>
        </Box>
      </>
    );
  };

  useEffect(() => {
    async function runApi() {
      if (firstCategory)
        await axios
          .get(`${baseURL}/AdminGetProductByCategoryId?Id=${firstCategory}`)
          .then((response) => {
            setProduct(response.data);
            setRecords(response.data);
          })
          .catch((err) => {});
    }
    runApi();
  }, [firstCategory]);

  const getProductByCategory = async (id: any) => {
    setFirstCat(id);
    await axios
      .get(`${baseURL}/AdminGetProductByCategoryId?Id=${id}`)
      .then((response) => {
        setProduct(response.data);
        setRecords(response.data);
      })
      .catch((err) => {
        message.error("Hata! Veriler Yüklenemedi " + err.message);
      });
  };
  const deleteProduct = async () => {
    await axios
      .delete(`${baseURL}/DeleteProduct?Id=${idDelete}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    const newData = products
      ? products.filter((row) => row.id !== idDelete)
      : null;
    setProduct(newData);
    setRecords(newData);
    setShowDelete(false);
  };
  const setProductVisibility = async (id: string, activity: boolean) => {
    activity = !activity;
    await axios
      .put(`${baseURL}/UpdateProductActivity`, { id, activity })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((err) => {
        message.error("Hata! İşlem Başarısız " + err.message);
      });
    const newData = products
      ? products.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              isActive: !row.isActive,
            };
            return updated;
          }
          return row;
        })
      : null;
    setProduct(newData);
    setRecords(newData);
  };

  const setProductStar = async (id: string, starred: boolean) => {
    starred = !starred;
    await axios
      .put(`${baseURL}/UpdateProductStarred`, { id, starred })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((err) => {
        message.error("Hata! İşlem Başarısız " + err.message);
      });
    const newData = products
      ? products.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              isStarred: !row.isStarred,
            };
            return updated;
          }
          return row;
        })
      : null;
    setProduct(newData);
    setRecords(newData);
  };
  const setProductGhost = async (id: string, ghosty: boolean) => {
    ghosty = !ghosty;
    await axios
      .put(`${baseURL}/UpdateProductGhost`, { id, ghosty })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((err) => {
        message.error("Hata! İşlem Başarısız " + err.message);
      });
    const newData = products
      ? products.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              isGhost: !row.isGhost,
            };
            return updated;
          }
          return row;
        })
      : null;
    setProduct(newData);
    setRecords(newData);
  };
  const columns = [
    {
      name: "Resim",
      cell: (row: any) => (
        <Box>
          {row.imageUrl ? (
            <CardMedia
              component="img"
              src={`${imageBaseUrl}${row.imageUrl}`}
              style={{ width: "60px", height: "60px", borderRadius: "5%" }}
              loading="lazy"
            />
          ) : (
            <CardMedia
              component="img"
              src={Avatar}
              style={{ width: "60px", height: "60px", borderRadius: "5%" }}
              loading="lazy"
            />
          )}
        </Box>
      ),
    },
    {
      name: "Başlık",
      sortable: true,
      selector: (row: { title: any }) => row.title,
    },
    {
      name: "Açıklama",
      sortable: true,
      selector: (row: { description: any }) => row.description,
    },
    {
      name: "Fiyat",
      sortable: true,
      selector: (row: { price: any }) => row.price,
    },
    {
      name: "İndirimli Fiyat",
      sortable: true,
      selector: (row: { discountedPrice: any }) => row.discountedPrice,
    },
    {
      cell: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <Box onClick={() => setProductVisibility(row.id, row.isActive)}>
            {row.isActive ? (
              <Visibility color="action" />
            ) : (
              <VisibilityOff color="action" />
            )}
          </Box>
          <Box onClick={() => setProductStar(row.id, row.isStarred)}>
            {row.isStarred ? (
              <Star style={{ color: "#e5e500" }} />
            ) : (
              <StarBorderOutlined color="action" />
            )}
          </Box>
          <Box onClick={() => setProductGhost(row.id, row.isGhost)}>
            {row.isGhost ? <Ghost color="#bbb" /> : <Ghost />}
          </Box>
        </Box>
      ),
    },
    {
      name: "Action",
      cell: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Box
            onClick={() => {
              setShowDelete(true);
              setIdDelete(row.id);
            }}
          >
            <DeleteForever color="primary" />
          </Box>

          <Link to={`/update-product/${row.id}`}>
            <Box>
              <Update color="primary" />
            </Box>
          </Link>
        </Box>
      ),
    },
  ];

  const [records, setRecords] = useState(products);
  const handlefilter = (event: any) => {
    const newData = products
      ? products.filter((row) => {
          return row.title
            .toLocaleLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      : null;
    setRecords(newData);
  };

  return (
    <>
      <div
        className="dataTable"
        style={{ marginLeft: "25px", marginRight: "25px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <div>
            <Link to="/update-bulk-product-price">
              <Button variant="contained">
                <Typography>Toplu fiyat güncelleme</Typography>
              </Button>
            </Link>
          </div>
          <div>
            <Link to="/create-product">
              <Button>
                <AddCircle style={{ fontSize: "55px", width: "60px" }} />
              </Button>
            </Link>
          </div>
        </div>
        <Box>
          <Box
            sx={{
              alignItems: "center",
              justifyContent: "start",
              display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
            }}
          >
            <div>
              <TextField
                label="Bölüm Seçin"
                value={firstCategory}
                onChange={(e) => {
                  getProductByCategory(e.target.value);
                }}
                select
                sx={{ m: 1 }}
              >
                {userCategoryDatas
                  ? userCategoryDatas.map((data) => {
                      return (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </TextField>
            </div>
            <div>
              <TextField
                type="text"
                onChange={handlefilter}
                label="Ara..."
                sx={{ m: 1 }}
              ></TextField>
            </div>
          </Box>
          {records ? (
            <DataTable
              columns={columns}
              data={records}
              responsive
              highlightOnHover
              dense
              persistTableHead
              pointerOnHover
              striped
              title="Ürün Bilgileri Listesi"
              fixedHeader
              pagination
            />
          ) : null}
        </Box>
        {isShowDeleteModal ? (
          <Box
            sx={{
              display: "block",
              position: "fixed",
              zIndex: "100",
              paddingTop: "100px",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              overflow: "auto",

              backgroundColor: "rgba(0,0,0,0.4)",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#fefefe",
                margin: "auto",
                overflow: "auto",
                paddingTop: "10px",
                paddingRight: "20px",
                border: "1px solid #888",
                height: "40%",
                width: "40%",
                display: { xs: "none", sm: "none", md: "flex" },
                flexDirection: "column",
                alignItems: "space-around",
                borderRadius: "15px",
              }}
            >
              <Button
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                <CloseRounded />
              </Button>
              <ShowDelete />
            </Box>

            <Box
              sx={{
                backgroundColor: "#fefefe",
                margin: "auto",
                overflow: "auto",
                paddingTop: "10px",
                paddingRight: "20px",
                border: "1px solid #888",
                height: "40%",
                width: "90%",
                display: { xs: "flex", sm: "flex", md: "none" },
                flexDirection: "column",
                alignItems: "space-around",
                borderRadius: "15px",
              }}
            >
              <Button
                onClick={() => {
                  setShowDelete(false);
                }}
              >
                <CloseRounded />
              </Button>
              <ShowDelete />
            </Box>
          </Box>
        ) : null}
      </div>
    </>
  );
};

export default ListProduct;
