import { Box, Button, TextField, Typography } from "@mui/material";
import { Alert, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const InputOtpCode = () => {
  const baseURL = "/User";

  const [otpCode, setotpCode] = useState("");
  const { email } = useParams();
  const navigate = useNavigate();

  const [errorOtp, setErrorOtp] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const sendOtpCode = async () => {
    setErrorOtp("");
    setErrorDb("");
    await axios
      .post(`${baseURL}/VerifyOtpCode`, {
        email,
        otpCode,
      })
      .then((response) => {
        message.success("İşlem Başarılı ");
        if (response.data == true)
          navigate(`/changepassword/${email}/${otpCode}`);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorOtp(error.response.data.errors.OtpCode);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  return (
    <div>
      <Box
        sx={{
          marginTop: "55px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "40%",

            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              {" "}
              <Typography textAlign="center">
                Mail kutunuza gelen kodu giriniz.
              </Typography>
            </Box>
            {errorOtp ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorOtp}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                type="text"
                label="Dogrulama kodunuz"
                onChange={(e) => setotpCode(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Doğrula
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "60%",
            flexDirection: "column",
            display: { xs: "none", sm: "none", md: "flex", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography textAlign="center">
                Mail kutunuza gelen kodu giriniz.
              </Typography>
            </Box>
            {errorOtp ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorOtp}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                type="text"
                label="Dogrulama kodunuz"
                onChange={(e) => setotpCode(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Doğrula
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "95%",
            flexDirection: "column",
            display: { xs: "column", sm: "column", md: "none", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography textAlign="center">
                Mail kutunuza gelen kodu giriniz.
              </Typography>
            </Box>
            {errorOtp ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorOtp}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                type="text"
                label="Dogrulama kodunuz"
                onChange={(e) => setotpCode(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendOtpCode}>
                Doğrula
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default InputOtpCode;
