import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import {
  AddCircle,
  CloseRounded,
  DeleteForever,
  Update,
} from "@mui/icons-material";
import { Box, Button, CardMedia, TextField, Typography } from "@mui/material";
import UpdateCategory from "../Update/UpdateCategory";
import CreateCategory from "../Create/CreateCategory";
import axios from "axios";
import { Flex, Spin, message } from "antd";

export type getAllCategory = {
  id: string;
  name: string;
  imageUrl: string;
  orderNumber: Number;
};
const ListCategory = () => {
  const baseURL = "/Category";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userCategoryDatas, setCategoryData] = useState<
    getAllCategory[] | null
  >();
  const [idDelete, setIdDelete] = useState("");
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deleteCategory}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };

  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/AdminGetAllCategory`, {
          headers: {
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json; charset=UTF-8",
          },
        })
        .then((response) => {
          setLoading(false);
          setCategoryData(response.data);
          setRecords(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  const deleteCategory = async () => {
    await axios
      .delete(`${baseURL}/DeleteCategory?Id=${idDelete}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    const newData = userCategoryDatas
      ? userCategoryDatas.filter((row) => row.id !== idDelete)
      : null;
    setShowDelete(false);
    setCategoryData(newData);
    setRecords(newData);
  };
  const updateOrderNumber = async (OrderNumberStr: String, id: string) => {
    console.log(OrderNumberStr);
    var OrderNumber = Number(OrderNumberStr);
    await axios
      .put(`${baseURL}/UpdateCategoryOrderNumber`, { id, OrderNumber })
      .then((response) => {})
      .catch((err) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + err.message);
      });
    const newData = userCategoryDatas
      ? userCategoryDatas.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              orderNumber: Number(OrderNumberStr),
            };
            return updated;
          }
          return row;
        })
      : null;
    setCategoryData(newData);
    setRecords(newData);
  };
  const [update, setUpdateVisible] = useState(false);
  const [create, setCreateVisible] = useState(false);
  const columns = [
    {
      name: "Resim",
      cell: (row: any) => (
        <Box>
          {row.imageUrl ? (
            <CardMedia
              component="img"
              src={`${imageBaseUrl}${row.imageUrl}`}
              style={{ width: "60px", height: "60px", borderRadius: "5%" }}
              loading="lazy"
            />
          ) : (
            <CardMedia
              component="img"
              src={Avatar}
              style={{ width: "60px", height: "60px", borderRadius: "5%" }}
              loading="lazy"
            />
          )}
        </Box>
      ),
    },
    {
      name: "Bölüm adı",
      selector: (row: { name: string }) => row.name,
      sortable: true,
    },
    {
      name: "Sıra",
      cell: (row: any) => (
        <Box>
          <TextField
            size="small"
            type="number"
            value={String(row.orderNumber)}
            onChange={(e) => updateOrderNumber(e.target.value, row.id)}
          />
        </Box>
      ),
    },
    {
      name: "Action",
      cell: (row: any) => (
        <Box>
          <Button
            onClick={() => {
              setShowDelete(true);
              setIdDelete(row.id);
            }}
          >
            <DeleteForever />
          </Button>

          <Button
            onClick={() => {
              setId(row.id);
              setUpdateVisible(true);
            }}
          >
            <Update />
          </Button>
        </Box>
      ),
    },
  ];

  const [records, setRecords] = useState(userCategoryDatas);
  const handlefilter = (event: any) => {
    const newData = userCategoryDatas
      ? userCategoryDatas.filter((row) => {
          return row.name
            .toLocaleLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      : null;
    setRecords(newData);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            backgroundColor: "white",
          }}
        >
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </Box>
      ) : (
        <>
          {" "}
          <div
            className="dataTable"
            style={{ marginLeft: "25px", marginRight: "25px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div>
                <Button
                  onClick={() => {
                    setCreateVisible(true);
                  }}
                >
                  <AddCircle style={{ fontSize: "55px", width: "60px" }} />
                </Button>
              </div>
            </div>
            <div>
              <TextField type="text" onChange={handlefilter} label="Ara..." />
            </div>
            {records ? (
              <DataTable
                columns={columns}
                data={records}
                responsive
                highlightOnHover
                dense
                persistTableHead
                pointerOnHover
                striped
                title="Bölüm Bilgileri Listesi"
                fixedHeader
                pagination
              />
            ) : null}
            {update ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "50",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #888",
                    width: "80%",
                  }}
                >
                  <Button
                    onClick={() => {
                      setUpdateVisible(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <UpdateCategory id={id} />
                </Box>
              </Box>
            ) : null}
            {create ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "50",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #888",
                    width: "80%",
                  }}
                >
                  <Button
                    onClick={() => {
                      setCreateVisible(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <CreateCategory />
                </Box>
              </Box>
            ) : null}
            {isShowDeleteModal ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "100",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "40%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "90%",
                    display: { xs: "flex", sm: "flex", md: "none" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>
              </Box>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ListCategory;
