import { Box, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Avatar from "../../../assets/images/profile-avatar.jpg";
import { message } from "antd";

export type getAllUser = {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  imageUrl: string;
  createdAt: string;
  status: any;
};
export type getUserRole = {
  id: string;
  name: string;
};
const Profile = () => {
  const baseURL = "/User";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userDatas, setUserData] = useState<getAllUser | null>();
  const [roles, setUserRole] = useState<getUserRole[] | null>(null);
  const { id } = useParams();
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetAllUserRole`)
        .then((response) => {
          setUserRole(response.data);
        })
        .catch((error) => {});
      await axios
        .get(`${baseURL}/GetUserById?Id=${id}`)
        .then((response) => {
          {
            setUserData(response.data);
          }
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",

        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "10px",
          gap: "25px",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h4" textAlign="center">
          Profil Bilgileri
        </Typography>
        <Box
          sx={{
            display: { xs: "block", sm: "block", md: "flex" },
            justifyContent: "space-around",
            alignItems: "center",
            gap: "55px",
          }}
        >
          <Box>
            {userDatas?.imageUrl ? (
              <img
                src={`${imageBaseUrl}${userDatas?.imageUrl}`}
                style={{ width: "280px", height: "310px", borderRadius: "5px" }}
                loading="lazy"
              />
            ) : (
              <img
                src={Avatar}
                style={{ width: "280px", height: "310px", borderRadius: "5px" }}
                loading="lazy"
              />
            )}
          </Box>

          <Box sx={{ textAlign: "start", color: "#556565" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Kullanıcı adı:</Typography>
              <Typography variant="h5" padding={1}>
                {userDatas?.userName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Email adresi:</Typography>
              <Typography variant="h5" padding={1}>
                {userDatas?.email}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {" "}
              <Typography variant="h6">Telefon numarası:</Typography>
              <Typography variant="h5" padding={1}>
                {userDatas?.phoneNumber}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6">Rolü:</Typography>
              <Typography variant="h5" padding={1}>
                {roles?.map((valueRol: any) => {
                  if (userDatas?.roleId == valueRol.id) {
                    return <Box>{valueRol.name}</Box>;
                  }
                })}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {" "}
              <Typography variant="h6">Durum:</Typography>
              <Typography variant="h5" padding={1}>
                {userDatas?.status == "1" ? (
                  <Box
                    sx={{
                      backgroundColor: "#11bb11",
                      padding: "6px",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    Aktif
                  </Box>
                ) : (
                  <Box
                    sx={{
                      backgroundColor: "#ff1111",
                      padding: "6px",
                      borderRadius: "5px",
                      color: "white",
                    }}
                  >
                    Bloke
                  </Box>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
