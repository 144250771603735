import React, { useEffect, useState } from "react";
import ListProduct from "./ProductTemplate/List/ProductList";
import { Box, Typography } from "@mui/material";
import {
  Category,
  MarkEmailRead,
  MarkEmailUnread,
  MessageSharp,
  Traffic,
} from "@mui/icons-material";
import { ProductFilled } from "@ant-design/icons";
import axios from "axios";
import { Flex, Spin, message } from "antd";
export type visitTypes = {
  numberOfVisits: number;
};
const Dashboard = () => {
  const [products, setProduct] = useState();
  const [category, setCategory] = useState();
  const [read, setRead] = useState();
  const [unread, setUnRead] = useState();
  const [visitNumber, setVisitNumber] = useState<visitTypes | null>(null);
  const baseURL = "/Product";
  const categoryBaseURL = "/Category";
  const baseCustomerURL = "/CustomerContact";
  const baseVisitURL = "/Visit";

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${categoryBaseURL}/AdminGetAllCategory`)
        .then((response) => {
          setCategory(response.data.length);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
      await axios
        .get(`${baseURL}/AdminGetAllProduct`)
        .then((response) => {
          setProduct(response.data.length);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
      await axios
        .get(`${baseCustomerURL}/GetCustomerContactByFilter?isRead=false`)
        .then((response) => {
          setUnRead(response.data.length);
        })
        .catch((err) => {});
      await axios
        .get(`${baseCustomerURL}/GetCustomerContactByFilter?isRead=true`)
        .then((response) => {
          setRead(response.data.length);
        })
        .catch((err) => {});
      await axios
        .get(`${baseVisitURL}/GetVisit`)
        .then((response) => {
          response.data.map((value: any) => setVisitNumber(value));
        })
        .catch((err) => {});
    }
    getApi();
  }, []);
  const sum = (num1: number, num2: number) => {
    return num1 + num2;
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",

          marginTop: "15px",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "grid" },
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
            position: "relative",
            marginLeft: "85px",
            marginRight: "85px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ürünler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {products}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <ProductFilled style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Bölümler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {category}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Category style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {sum(read ? read : 0, unread ? unread : 0)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MessageSharp style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",

              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmuş Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {read}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailRead style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmamış Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {unread}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailUnread style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ziyaret
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {visitNumber?.numberOfVisits}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Traffic style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "grid", lg: "none" },
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            marginLeft: "35px",
            marginRight: "35px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ürünler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {products}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <ProductFilled style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Bölümler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {category}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Category style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {sum(read ? read : 0, unread ? unread : 0)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MessageSharp style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmuş Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {read}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailRead style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmamış Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {unread}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailUnread style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ziyaret
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {visitNumber?.numberOfVisits}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Traffic style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "grid", sm: "grid", md: "none", lg: "none" },
            gridTemplateColumns: "1fr",
            gap: "20px",
            marginLeft: "5px",
            marginRight: "5px",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ürünler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {products}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <ProductFilled style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Bölümler
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {category}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Category style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {read && unread ? read + unread : null}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MessageSharp style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmuş Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {read}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailRead style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Okunmamış Mesajlar
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {unread}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <MarkEmailUnread style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              padding: "15px",
              justifyContent: "space-between",
              borderRadius: "10px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography fontWeight="500" color="#b0b0b0">
                Ziyaret
              </Typography>
              <Typography fontWeight="700" fontSize="29px">
                {visitNumber?.numberOfVisits}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "blue",
                width: "75px",
                height: "75px",
                borderRadius: "20%",
              }}
            >
              <Traffic style={{ color: "white", fontSize: "20px" }} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ marginTop: "70px" }}>
        <ListProduct />
      </Box>
    </div>
  );
};

export default Dashboard;
