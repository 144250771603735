import { Box, Container } from "@mui/material";
import React from "react";

type setting = {
  isExplain: Boolean | undefined;
  explain: string | undefined;
};
const AnasayfaYazisi = (prop: setting) => {
  const { isExplain, explain } = prop;
  return (
    <Container maxWidth="lg" sx={{ padding: "5px" }}>
      {isExplain && explain ? (
        <Box
          sx={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
          dangerouslySetInnerHTML={{ __html: explain }}
        ></Box>
      ) : null}
    </Container>
  );
};

export default AnasayfaYazisi;
