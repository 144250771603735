import React, { useEffect, useState } from "react";
import { Menu, Layout, Button, theme } from "antd";
import { useNavigate, Outlet } from "react-router-dom";
import ToggleThemeButton from "./ToggleThemeButton";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import {
  BusinessCenterRounded,
  Dashboard,
  MessageSharp,
  Pages,
  Person,
  RestaurantMenu,
} from "@mui/icons-material";

import { Box, CardMedia } from "@mui/material";

import TransitionMenu from "./TransitionMenu";
import Cookies from "js-cookie";
import axios from "axios";

const SidebarComp = () => {
  const { Header, Sider } = Layout;
  const [darkTheme, setDarkTheme] = useState(false);
  const navigate = useNavigate();

  if (!Cookies.get("token")) navigate("/login");
  const [collapsed, setCollapsed] = useState(false);
  const baseURLSetting = "/Setting";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/`;
  const toggleTheme = () => {
    const value = String(!darkTheme);

    localStorage.setItem("theme", value);

    setDarkTheme(!darkTheme);
  };
  const CollapsedButton = () => {
    const valueCol = String(!collapsed);

    localStorage.setItem("collapsed", valueCol);
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    var theme = localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : null;
    theme = theme ? JSON.parse(theme) : null;

    if (Boolean(theme)) setDarkTheme(true);
    else setDarkTheme(false);

    var collapsed = localStorage.getItem("collapsed")
      ? localStorage.getItem("collapsed")
      : null;
    collapsed = collapsed ? JSON.parse(collapsed) : null;

    if (Boolean(collapsed)) setCollapsed(true);
    else setCollapsed(false);

    async function getApi() {
      await axios
        .get(`${baseURLSetting}/GetAllSetting`)
        .then((response) => {
          response.data.map((data: any) => {
            setSettingId(data.id);
            setLogo(data.logoUrl);
          });
        })
        .catch((err) => {});
    }
    getApi();
  }, []);
  const [settingId, setSettingId] = useState("");
  const [logo, setLogo] = useState("");

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div>
      <Layout
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          {" "}
          <Sider
            collapsed={collapsed}
            collapsible
            breakpoint="md"
            trigger={null}
            theme={darkTheme ? "dark" : "light"}
            color="#fff"
            width={250}
            style={{
              zIndex: "10",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                padding: "10px",
              }}
            >
              {logo ? (
                <CardMedia
                  component="img"
                  src={`${imageBaseUrl}get/${logo}`}
                  style={{
                    width: "65px",
                    height: "65px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1.5rem",
                  }}
                />
              ) : null}
            </div>

            <Menu
              theme={darkTheme ? "dark" : "light"}
              mode="inline"
              onClick={({ key }) => {
                navigate(key);
              }}
              style={{
                height: "100vh",
                display: "flex",
                gap: "15px",

                marginTop: "2rem",
                flexDirection: "column",
                fontSize: "1rem",
              }}
            >
              <Menu.Item
                key="/dashboard"
                icon={<Dashboard />}
                style={{ float: "left" }}
              >
                Dashboard
              </Menu.Item>
              <Menu.Item key="/user" icon={<Person />}>
                Kullanıcılar
              </Menu.Item>
              <Menu.SubMenu key="menu" icon={<RestaurantMenu />} title="Menü">
                <Menu.Item key="/menu-section">Bölümler</Menu.Item>
                <Menu.Item key="/product">Ürünler</Menu.Item>
              </Menu.SubMenu>
              <Menu.SubMenu
                key="business"
                icon={<BusinessCenterRounded />}
                title="Araçlar"
              >
                <Menu.Item key="/contact">İletişim</Menu.Item>
                <Menu.Item key="/setting">Website</Menu.Item>
                <Menu.Item key="/socialmedia">Sosyal Medya</Menu.Item>
                <Menu.Item key="/workingday">Çalışma Zamanları</Menu.Item>
              </Menu.SubMenu>
              <Menu.Item key="/pagelist" icon={<Pages />}>
                Sayfalar
              </Menu.Item>
              <Menu.Item key="/customer-messages" icon={<MessageSharp />}>
                Mesajlar
              </Menu.Item>
              <div>
                <ToggleThemeButton
                  darkTheme={darkTheme}
                  toggleTheme={() => {
                    toggleTheme();
                  }}
                  style={{
                    left: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "1rem",
                  }}
                />
              </div>
            </Menu>
          </Sider>
        </Box>

        <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
          {collapsed ? (
            <Sider
              trigger={null}
              theme={darkTheme ? "dark" : "light"}
              color="#fff"
              width={250}
              style={{
                zIndex: "10",
                transition: "0.5s",
                height: "100vh",

                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  padding: "10px",
                }}
              >
                {logo ? (
                  <CardMedia
                    component="img"
                    src={`${imageBaseUrl}get/${logo}`}
                    style={{
                      width: "65px",
                      height: "65px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1.5rem",
                    }}
                  />
                ) : null}
              </div>
              <Menu
                theme={darkTheme ? "dark" : "light"}
                mode="inline"
                onClick={({ key }) => {
                  navigate(key);
                }}
                style={{
                  height: "88vh",
                  display: "flex",
                  gap: "15px",
                  marginTop: "2rem",
                  flexDirection: "column",
                  fontSize: "1rem",
                  position: "relative",
                }}
              >
                <Menu.Item
                  onClick={() => setCollapsed(false)}
                  key="/dashboard"
                  icon={<Dashboard />}
                  style={{ float: "left" }}
                >
                  Dashboard
                </Menu.Item>
                <Menu.Item
                  key="/user"
                  icon={<Person />}
                  onClick={() => setCollapsed(false)}
                >
                  Kullanici
                </Menu.Item>
                <Menu.SubMenu key="menu" icon={<RestaurantMenu />} title="Menu">
                  <Menu.Item
                    onClick={() => setCollapsed(false)}
                    key="/menu-section"
                  >
                    Bolumler
                  </Menu.Item>
                  <Menu.Item onClick={() => setCollapsed(false)} key="/product">
                    Urunler
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu
                  key="business"
                  icon={<BusinessCenterRounded />}
                  title="Araçlar"
                >
                  <Menu.Item onClick={() => setCollapsed(false)} key="/contact">
                    Iletisim
                  </Menu.Item>
                  <Menu.Item onClick={() => setCollapsed(false)} key="/setting">
                    Website
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setCollapsed(false)}
                    key="/socialmedia"
                  >
                    Sosyal Medya
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => setCollapsed(false)}
                    key="/workingday"
                  >
                    Calisma Zamanlari
                  </Menu.Item>
                </Menu.SubMenu>
                <Menu.Item
                  onClick={() => setCollapsed(false)}
                  key="/pagelist"
                  icon={<Pages />}
                >
                  Sayfa
                </Menu.Item>
                <Menu.Item
                  onClick={() => setCollapsed(false)}
                  key="/customer-messages"
                  icon={<MessageSharp />}
                >
                  Mesajlar
                </Menu.Item>
                <div>
                  <ToggleThemeButton
                    darkTheme={darkTheme}
                    toggleTheme={() => {
                      toggleTheme();
                    }}
                    style={{
                      left: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "1rem",
                    }}
                  />
                </div>
              </Menu>
            </Sider>
          ) : null}
        </Box>

        <Layout style={{ backgroundColor: "#fff" }}>
          <Header
            style={{
              backgroundColor: "#fff",
              display: "flex",
              padding: "0",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              type="text"
              icon={
                collapsed ? (
                  <MenuUnfoldOutlined style={{ fontSize: "25px" }} />
                ) : (
                  <MenuFoldOutlined style={{ fontSize: "25px" }} />
                )
              }
              onClick={() => CollapsedButton()}
            />

            <TransitionMenu />
          </Header>
          <Outlet />
        </Layout>
      </Layout>
    </div>
  );
};

export default SidebarComp;
