import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {
  CloseRounded,
  CloudUpload,
  Delete,
  Save,
  ViewListOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import {
  Button,
  CardMedia,
  CircularProgress,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import axios from "axios";
import htmlToDraft from "html-to-draftjs";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TextArea from "antd/es/input/TextArea";
import { message } from "antd";

export type fileTypes = {
  imageUrl: string;
  imageFile: any;
};
export type sliderType = {
  id: string;
  imageUrl: string;
};
export default function WebSetting() {
  const baseURL = "/Banner";
  const baseURLSetting = "/Setting";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/`;
  const [pageContent, setpageContent] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURLSetting}/GetAllSetting`)
        .then((response) => {
          response.data.map((data: any) => {
            setSettingId(data.id);
            setLogo(data.logoUrl);
            setBrandName(data.brandName);
            setBanner(data.isBanner);
            checkedExplain(data.isExplain);
            checkedPopuler(data.isPopuler);
            setpageTitle(data.titleSeo);
            setpageDescription(data.descriptionSeo);
            data.isPageList ? checkedList("list") : checkedList("module");
            const blocksFromHtml = htmlToDraft(data.explain);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
              contentBlocks,
              entityMap
            );
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState);
          });
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);

  const [isBanner, setBanner] = useState<boolean>(false);
  const [isExplain, checkedExplain] = useState<boolean>(false);
  const [isPopuler, checkedPopuler] = useState<boolean>(false);
  const [isList, checkedList] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [titleSeo, setpageTitle] = useState("");
  const [descriptionSeo, setpageDescription] = useState("");

  const [files, setFile] = useState<any>(null);
  const [getFiles, setgetFile] = useState<sliderType[] | null>(null);

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetAllBanner`)
        .then((response) => {
          setgetFile(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
    setpageContent(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  const addBanner = async () => {
    const formData = new FormData();

    // Append each selected file to the FormData object
    for (let i = 0; i < files.length; i++) {
      formData.append("ImageFiles", files[i]);
    }
    await axios
      .post(`${baseURL}/CreateBanner`, formData, {
        onUploadProgress: (progressEvent: any) => {
          // Calculate and update the upload progress
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Ekleme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Ekleme İşlemi Başarısız " + err.message);
      });
  };
  const deleteBanner = async () => {
    var fileName = deletBannerName;
    await axios
      .delete(
        `${baseURL}/DeleteBanner?id=${deletBannerId}&fileName=${fileName}`
      )
      .then((response) => {
        const newData = getFiles
          ? getFiles?.filter((i) => i.id !== deletBannerId)
          : null;
        setgetFile(newData);
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    setShowDelete(false);
  };
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const handleChange = (e: any) => {
    const newFiles = [];
    for (let i = 0; i < e.target.files.length; i++) {
      newFiles.push(e.target.files[i]);
    }
    setFile(newFiles);
  };
  const handleChangeLogo = (e: any) => {
    setLogoFile(e.target.files[0]);
  };
  const updateSeo = async () => {
    var id = settingId;
    await axios
      .put(`${baseURLSetting}/UpdateSeo`, { id, titleSeo, descriptionSeo })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  const [brandName, setBrandName] = useState("");
  const [logo, setLogo] = useState("");

  const setIsBanner = async (isBanner: any) => {
    setBanner(isBanner);
    var id = settingId;
    await axios
      .put(`${baseURLSetting}/UpdateBannerActive`, { id, isBanner })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  const setIsExplain = async (isExplain: any) => {
    checkedExplain(isExplain);
    var id = settingId;
    await axios
      .put(`${baseURLSetting}/UpdateExplainActive`, { id, isExplain })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  const setExplain = async () => {
    var id = settingId;
    var explain = pageContent;
    await axios
      .put(`${baseURLSetting}/UpdateExplain`, { id, explain })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const [deletBannerId, setDeleteBannerId] = useState("");
  const [deletBannerName, setDeleteBannerName] = useState("");
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediginizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deleteBanner}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayir</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [isShowDeleteModalLogo, setShowDeleteLogo] = useState(false);
  const ShowDeleteLogo = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediginizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={handleImageDelete}>Evet</Button>
            <Button onClick={() => setShowDeleteLogo(false)}>Hayir</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [logoFile, setLogoFile] = useState<any>("");
  const [settingId, setSettingId] = useState(null);
  const setLogoBrand = async () => {
    const formData = new FormData();
    var id = settingId;
    if (id != null) formData.append("id", id);
    formData.append("brandName", brandName);
    formData.append("logo", logo);
    if (logoFile != null) formData.append("ImageFile", logoFile);

    await axios
      .put(`${baseURLSetting}/UpdateLogoAndBrand`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  const setIsPageList = async (
    event: React.MouseEvent<HTMLElement>,
    nextView: string
  ) => {
    var isPageList;
    if (nextView === "list") isPageList = true;
    else isPageList = false;
    var id = settingId;
    checkedList(nextView);
    await axios
      .put(`${baseURLSetting}/UpdatePageListActive`, { id, isPageList })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  const setIsPopular = async (isPopuler: boolean) => {
    checkedPopuler(isPopuler);
    var id = settingId;
    await axios
      .put(`${baseURLSetting}/UpdatePopulerActive`, { id, isPopuler })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((error) => {
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };

  const handleImageDelete = async () => {
    await axios
      .delete(`${imageBaseUrl}delete/${logo}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Silme İşlemi Başarısız " + error.message);
      });
    setLogo("");
    setFile(null);
    setLogoBrand();
    setShowDeleteLogo(false);
  };
  return (
    <Box>
      <Box>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Anasayfa Düzenlemesi
          </Typography>
          <div>
            <Box>
              <Box
                sx={{
                  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  margin: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <TextField
                  label="Başlık (SEO)"
                  value={titleSeo}
                  onChange={(e) => setpageTitle(e.target.value)}
                  id="outlined-start-adornment"
                  sx={{ m: 1 }}
                />
                <label>Açıklama (SEO)</label>
                <TextArea
                  placeholder="Açıklama (SEO)"
                  value={descriptionSeo}
                  style={{ margin: "5px" }}
                  onChange={(e) => setpageDescription(e.target.value)}
                  id="outlined-start-adornment"
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    type="submit"
                    startIcon={<Save />}
                    variant="contained"
                    onClick={updateSeo}
                    sx={{ m: 1 }}
                  >
                    Kaydet
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
                  alignItems: "center",
                  justifyContent: "space-around",
                  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                  margin: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {logo ? (
                    <Box>
                      <Button onClick={() => setShowDeleteLogo(true)}>
                        <Delete />
                      </Button>
                    </Box>
                  ) : null}
                  {logo ? (
                    <CardMedia
                      loading="lazy"
                      component="img"
                      style={{
                        width: "240px",
                        height: "240px",
                      }}
                      src={`${imageBaseUrl}get/${logo}`}
                    />
                  ) : (
                    <CardMedia
                      loading="lazy"
                      component="img"
                      src={logoFile ? URL.createObjectURL(logoFile) : Avatar}
                      style={{
                        width: "240px",
                        height: "240px",
                        borderRadius: "5%",
                      }}
                    />
                  )}
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUpload />}
                  >
                    Logo
                    <TextField
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleChangeLogo}
                    />
                  </Button>
                </Box>
                <Box>
                  <TextField
                    type="text"
                    label="marka adi"
                    value={brandName}
                    sx={{ m: 1 }}
                    onChange={(e) => setBrandName(e.target.value)}
                  ></TextField>
                </Box>
                <Box>
                  <Button
                    type="submit"
                    startIcon={<Save />}
                    variant="contained"
                    sx={{ m: 1 }}
                    onClick={setLogoBrand}
                  >
                    Kaydet
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
          <Box
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              margin: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Typography>Banner görünsün mü?</Typography>
              <Switch
                onChange={(e) => {
                  setIsBanner(e.target.checked);
                }}
                checked={isBanner}
              />
            </Box>
            <Box>
              <Box>
                {uploadProgress > 0 && (
                  <div>
                    <p>Yukleniyor: {uploadProgress}%</p>
                    <CircularProgress
                      value={uploadProgress}
                      variant="determinate"
                    />
                  </div>
                )}
              </Box>
              {files ? (
                <Box>
                  <Typography paddingLeft={20}>
                    {files.length} adet dosya secildi
                  </Typography>
                </Box>
              ) : null}
              <Box sx={{ marginLeft: "10%", marginRight: "10%" }}>
                {getFiles ? (
                  <Carousel responsive={responsive}>
                    {getFiles.map((file, i) => {
                      return (
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <Box>
                            <Button
                              onClick={() => {
                                setShowDelete(true);
                                setDeleteBannerId(file.id);
                                setDeleteBannerName(file.imageUrl);
                              }}
                            >
                              <Delete />
                            </Button>
                          </Box>
                          <CardMedia
                            loading="lazy"
                            key={i}
                            src={`${imageBaseUrl}get/${file.imageUrl}`}
                            component="img"
                            height={550}
                          />
                        </Box>
                      );
                    })}
                  </Carousel>
                ) : (
                  <CardMedia
                    component="img"
                    loading="lazy"
                    src={Avatar}
                    height={610}
                  />
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  sx={{ m: 1 }}
                  startIcon={<CloudUpload />}
                >
                  Resim Ekle
                  <TextField
                    type="file"
                    sx={{ m: 1 }}
                    style={{ display: "none" }}
                    onChange={handleChange}
                    inputProps={{
                      multiple: true,
                    }}
                  />
                </Button>
                <Button
                  type="submit"
                  startIcon={<Save />}
                  variant="contained"
                  onClick={addBanner}
                >
                  Kaydet
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
              margin: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "30px",
              }}
            >
              <Typography>Anasayfa içerik yazısı görünsün mü?</Typography>
              <Switch
                onChange={(e) => {
                  setIsExplain(e.target.checked);
                }}
                checked={isExplain}
              />
            </Box>
            <Box
              sx={{
                margin: "20px",
              }}
            >
              <Editor
                placeholder="Icerik olusturun..."
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                toolbarStyle={{
                  border: "1px solid #ccc",
                  borderRadius: "0.313rem",
                }}
                editorStyle={{
                  padding: "0.625rem",
                  fontFamily: "montserrat",
                  border: "1px solid #ccc",
                  marginTop: "1rem",
                  backgroundColor: "#fff",
                  borderRadius: "0.625rem",
                  minHeight: "10rem",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Button
                  type="submit"
                  startIcon={<Save />}
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={setExplain}
                >
                  Kaydet
                </Button>
              </Box>
            </Box>
          </Box>
          <div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "30px",
                boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                margin: "20px",
              }}
            >
              <Typography>
                Anasayfada popüler ürünler bölümü görünsün mü?
              </Typography>
              <Switch
                onChange={(e) => {
                  setIsPopular(e.target.checked);
                }}
                checked={isPopuler}
              />
            </Box>
          </div>
          <div>
            <Box
              sx={{
                boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
                padding: "20px",
                margin: "20px",
                display: "flex",
                alignItems: "center",
                gap: "25px",
              }}
            >
              <Box>Anasayfa ürünler bölümü için düzen seçiniz</Box>
              <ToggleButtonGroup
                orientation="horizontal"
                value={isList}
                exclusive
                onChange={setIsPageList}
              >
                <ToggleButton value="list" aria-label="list">
                  <ViewListOutlined />
                </ToggleButton>
                <ToggleButton value="module" aria-label="module">
                  <ViewModuleOutlined />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </div>
        </div>
      </Box>
      {isShowDeleteModal ? (
        <Box
          sx={{
            display: "block",
            position: "fixed",
            zIndex: "100",
            paddingTop: "100px",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",

            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "40%",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "90%",
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>
        </Box>
      ) : null}

      {isShowDeleteModalLogo ? (
        <Box
          sx={{
            display: "block",
            position: "fixed",
            zIndex: "100",
            paddingTop: "100px",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",

            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "40%",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDeleteLogo(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDeleteLogo />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "90%",
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDeleteLogo(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDeleteLogo />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
