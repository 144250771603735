import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  AddCircle,
  CloseRounded,
  DeleteForever,
  Update,
} from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Flex, Spin, message } from "antd";
export type getAllUser = {
  id: string;
  pageName: string;
  pageContent: string;
  orderNumber: Number;
};
const PageList = () => {
  const baseURL = "/Page";
  const [userDatas, setUserData] = useState<getAllUser[] | null>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetAllPage`)
        .then((response) => {
          response.data.map((data: any) => {
            var truncatedContent = data.pageContent;
            if (truncatedContent.length > 100) {
              truncatedContent = data.pageContent.substring(0, 110) + "...";
            }
            data.pageContent = (
              <div
                dangerouslySetInnerHTML={{
                  __html: truncatedContent,
                }}
              ></div>
            );
          });

          setUserData(response.data);
          setRecords(response.data);
          setLoading(false);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  const deletePage = async () => {
    await axios
      .delete(`${baseURL}/DeletePage?Id=${idDelete}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    const newData = userDatas
      ? userDatas.filter((row) => row.id !== idDelete)
      : null;
    setUserData(newData);
    setRecords(newData);
    setShowDelete(false);
  };
  const updateOrderNumber = async (OrderNumberStr: String, id: string) => {
    console.log(OrderNumberStr);
    var OrderNumber = Number(OrderNumberStr);
    await axios
      .put(`${baseURL}/UpdatePageOrderNumber`, { id, OrderNumber })
      .then((response) => {})
      .catch((err) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + err.message);
      });
    const newData = userDatas
      ? userDatas.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              orderNumber: Number(OrderNumberStr),
            };
            return updated;
          }
          return row;
        })
      : null;
    setUserData(newData);
    setRecords(newData);
  };
  const [idDelete, setIdDelete] = useState("");
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deletePage}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [records, setRecords] = useState(userDatas);
  const handlefilter = (event: any) => {
    const newData = userDatas
      ? userDatas.filter((row) => {
          return row.pageName
            .toLocaleLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      : null;
    setRecords(newData);
  };
  const columns = [
    {
      name: "Sayfa Adı",
      selector: (row: { pageName: string }) => row.pageName,
      sortable: true,
    },
    {
      name: "İçerik",
      selector: (row: { pageContent: string }) => row.pageContent,
      sortable: true,
    },
    {
      name: "Sıra",
      cell: (row: any) => (
        <Box>
          <TextField
            size="small"
            type="number"
            value={String(row.orderNumber)}
            onChange={(e) => updateOrderNumber(e.target.value, row.id)}
          />
        </Box>
      ),
    },
    {
      name: "Action",
      cell: (row: any) => (
        <Box>
          <Button
            onClick={() => {
              setShowDelete(true);
              setIdDelete(row.id);
            }}
          >
            <DeleteForever />
          </Button>
          <Link to={`/update-page/${row.id}`}>
            <Button>
              <Update />
            </Button>
          </Link>
        </Box>
      ),
    },
  ];

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            backgroundColor: "white",
          }}
        >
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </Box>
      ) : (
        <>
          {" "}
          <div className="dataTable" style={{ margin: "30px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <div>
                <Link to="/create-page">
                  <Button>
                    <AddCircle style={{ fontSize: "55px", width: "60px" }} />
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              <TextField type="text" onChange={handlefilter} label="Ara..." />
            </div>
            {records ? (
              <DataTable
                columns={columns}
                data={records}
                fixedHeader
                pagination
                title="Sayfalar"
              />
            ) : null}
            {isShowDeleteModal ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "100",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "40%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "90%",
                    display: { xs: "flex", sm: "flex", md: "none" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>
              </Box>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default PageList;
