import React, { useEffect, useState } from "react";

function getSavedValue(key: string, initialValue: any) {
  var getItem = localStorage.getItem(key) ? localStorage.getItem(key) : null;
  const savedValue = getItem ? JSON.parse(getItem) : null;
  if (savedValue) {
    return savedValue;
  } else {
    return initialValue;
  }
}
const UseLocalStroge = (key: string, initialValue: any) => {
  let [value, setValue] = useState(() => {
    return getSavedValue(key, initialValue);
  });
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  });
  return [value, setValue];
};

export default UseLocalStroge;
