import React from "react";
import Usertable from "../../../../organisms/datatable/User/CustomDataTable";
import { Button } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Usertemplate = () => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <div>
          <Link to="/CreateUser">
            <Button>
              <AddCircle style={{ fontSize: "55px", width: "60px" }} />
            </Button>
          </Link>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <Usertable />
      </div>
    </div>
  );
};

export default Usertemplate;
