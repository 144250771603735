import {
  Box,
  Button,
  CardMedia,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import UseLocalStroge from "../../../Stroge/UseLocalStroge";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import { Flex, Spin, message } from "antd";
import { Save } from "@mui/icons-material";

export type getAllCategory = {
  id: string;
  name: string;
};
export type getProduct = {
  id: string;
  title: string;
  price: string;
  discountedPrice: string;
  imageUrl: string;
  categoryId: string;
};
const UpdateBulkPrice = () => {
  const baseURL = "/Product";
  const categoryBaseURL = "/Category";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userCategoryDatas, setCategoryData] = useState<
    getAllCategory[] | null
  >();
  const [firstCategory, setFirstCat] = UseLocalStroge("categoryName", "");
  const [products, setProduct] = useState<getProduct[] | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${categoryBaseURL}/AdminGetAllCategory`)
        .then((response) => {
          setCategoryData(response.data);
        })
        .catch((err) => {});
    }
    getApi();
  }, []);

  useEffect(() => {
    async function runApi() {
      if (firstCategory)
        if (firstCategory == "tum101") {
          await axios
            .get(`${baseURL}/AdminGetAllProduct`)
            .then((response) => {
              setProduct(response.data);
              setLoading(false);
            })
            .catch((err) => {
              message.error("Hata! Veriler Yüklenemedi " + err.message);
            });
        } else {
          await axios
            .get(`${baseURL}/AdminGetProductByCategoryId?Id=${firstCategory}`)
            .then((response) => {
              setProduct(response.data);
              setLoading(false);
            })
            .catch((err) => {
              message.error("Hata! Veriler Yüklenemedi " + err.message);
            });
        }
    }
    runApi();
  }, []);
  const getProductByCategory = async (id: any) => {
    setFirstCat(id);
    if (id == "tum101") {
      await axios
        .get(`${baseURL}/AdminGetAllProduct`)
        .then((response) => {
          setProduct(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    } else {
      await axios
        .get(`${baseURL}/AdminGetProductByCategoryId?Id=${id}`)
        .then((response) => {
          setProduct(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
  };

  const updateBulkPrice = async () => {
    await axios
      .put(`${baseURL}/BulkUpdateProductPrice`, products)
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  const [hasChange, setChange] = useState("");

  // Block navigating elsewhere when data has been entered into the input

  const handleInputChange = (event: any, entityId: any, property: any) => {
    const updatedEntities = products?.map((entity) => {
      if (entity.id === entityId) {
        return { ...entity, [property]: event.target.value };
      }
      return entity;
    });

    setProduct(updatedEntities ? updatedEntities : null);
  };
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            backgroundColor: "white",
          }}
        >
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </Box>
      ) : (
        <>
          <Box sx={{ margin: "25px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <TextField
                label="Bölüm Seçin"
                value={firstCategory}
                onChange={(e) => {
                  getProductByCategory(e.target.value);
                }}
                select
                sx={{ m: 1 }}
              >
                <MenuItem key="tum101" value="tum101">
                  Tümü
                </MenuItem>
                {userCategoryDatas
                  ? userCategoryDatas.map((data) => {
                      return (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      );
                    })
                  : null}
              </TextField>
              <Box>
                <Button
                  type="submit"
                  startIcon={<Save />}
                  variant="contained"
                  sx={{ m: 1 }}
                  onClick={updateBulkPrice}
                >
                  Kaydet
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "15px",
                overflow: "auto",
                position: "relative",
                maxHeight: "500px",
              }}
            >
              {products?.map((value, i) => (
                <Box
                  key={i}
                  sx={{
                    display: { xs: "block", sm: "block", md: "grid" },
                    alignItems: "center",
                    gap: "25px",
                    gridTemplateColumns: "3fr 2fr 1fr",
                    marginTop: "15px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "15px" }}
                  >
                    <Box>
                      {value.imageUrl ? (
                        <CardMedia
                          component="img"
                          src={`${imageBaseUrl}${value.imageUrl}`}
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "5%",
                          }}
                          loading="lazy"
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          src={Avatar}
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "5%",
                          }}
                          loading="lazy"
                        />
                      )}
                    </Box>
                    <Typography>{value.title}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "15px" }}
                  >
                    <TextField
                      value={value.price}
                      onChange={(e) => {
                        handleInputChange(e, value.id, "price");
                      }}
                      label="Fiyat"
                    >
                      {value.price}
                    </TextField>
                    <TextField
                      value={value.discountedPrice}
                      onChange={(e) => {
                        handleInputChange(e, value.id, "discountedPrice");
                      }}
                      label="indirimli fiyat"
                    >
                      {value.discountedPrice}
                    </TextField>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default UpdateBulkPrice;
