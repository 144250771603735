import React from "react";
import Navbar from "../organisms/navbar/navbar";
import { Outlet } from "react-router-dom";
const MainPageTemplate = () => {
  return (
    <div
      style={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Navbar />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default MainPageTemplate;
