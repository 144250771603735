import { Box, Container } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { message } from "antd";

export type pageTypes = {
  id: string;
  pageName: string;
  pageContent: string;
  pageUrl: string;
  titleSeo: string;
  descriptionSeo: string;
};
export type settingTypes = {
  id: string;
  isBanner: Boolean;
  isExplain: Boolean;
  isPopuler: Boolean;
  isPageList: Boolean;
  explain: string;
  titleSeo: string;
  descriptionSeo: string;
  logoUrl: string;
};
const PagesDynamic = () => {
  const baseURL = "/Page";
  const baseSettingURL = "/Setting";
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;
  const [pages, setPages] = useState<pageTypes | null>(null);
  const [setting, setSetting] = useState<settingTypes | null>(null);
  const { name } = useParams();

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetContentByPageUrl?pageUrl=${name}`)
        .then((response) => {
          setPages(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
      await axios
        .get(
          `${baseSettingURL}/GetAllSetting
        `
        )
        .then((response) => {
          response.data.map((value: any) => setSetting(value));
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);

  return (
    <Box>
      <Helmet>
        <link
          rel="icon"
          href={`${baseImageURL}/${setting?.logoUrl}`}
          type="image/x-icon"
        />
        <title>{pages?.titleSeo}</title>
        <meta name="description" content={pages?.descriptionSeo} />
      </Helmet>
      <Box
        sx={{
          paddingTop: "5%",
          whiteSpace: "pre-wrap",
          wordBreak: "break-word",
          minHeight: "550px",
        }}
      >
        <Container>
          {pages ? (
            <Box
              style={{ width: "auto", height: "auto" }}
              dangerouslySetInnerHTML={{ __html: pages?.pageContent }}
            ></Box>
          ) : null}
        </Container>
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default PagesDynamic;
