import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  CloseRounded,
  DeleteForever,
  MarkEmailRead,
  Markunread,
  ReadMore,
} from "@mui/icons-material";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import axios from "axios";
import { Flex, Spin, message } from "antd";

export type custumerTypes = {
  id: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  customerMessage: string;
  isRead: boolean;
  createdAt: any;
};
const CustomerContactView = () => {
  const baseURL = "/CustomerContact";
  const [customerDatas, setCustomerData] = useState<custumerTypes[] | null>();
  const [moreData, setMoreData] = useState<any>();
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetCustomerContactByFilter?isRead=false`)
        .then((response) => {
          setCustomerData(response.data);
          setRecords(response.data);
          setLoading(false);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);

  const deleteMessage = async () => {
    await axios
      .delete(`${baseURL}/DeleteCustomerContact?Id=${idDelete}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    const newData = customerDatas
      ? customerDatas.filter((row) => row.id !== idDelete)
      : null;
    setCustomerData(newData);
    setRecords(newData);
    setShowDelete(false);
  };
  const detailMessage = (id: any) => {
    records?.map((data) => {
      if (data.id === id) {
        setMoreData(data);
        return;
      }
    });
  };
  const [idDelete, setIdDelete] = useState("");
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediginizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={deleteMessage}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayir</Button>
          </Box>
        </Box>
      </>
    );
  };
  const ShowMore = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "start",
            textAlignLast: "justify",
          }}
        >
          <Box>
            <Typography variant="h6">Ad-Soyad</Typography>
            <Typography>{moreData.fullName}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Email</Typography>
            <Typography>{moreData.email}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Telefon</Typography>
            <Typography>{moreData.phoneNumber}</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Mesaj</Typography>
            <Typography
              paddingRight="20px"
              sx={{ textAlignLast: "justify", wordBreak: "break-word" }}
              whiteSpace="pre-wrap"
            >
              {moreData.customerMessage}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">Tarih</Typography>
            <Typography>{moreData.createdAt}</Typography>
          </Box>
        </Box>
      </>
    );
  };
  const columns = [
    {
      name: "Ad-Soyad",
      sortable: true,
      selector: (row: { fullName: any }) => row.fullName,
    },
    {
      name: "Email",
      sortable: true,
      selector: (row: { email: any }) => row.email,
    },
    {
      name: "Telefon",
      selector: (row: { phoneNumber: any }) => row.phoneNumber,
    },
    {
      name: "Mesaj",
      selector: (row: { customerMessage: any }) => row.customerMessage,
    },
    {
      name: "Tarih",
      sortable: true,
      selector: (row: { createdAt: any }) => row.createdAt,
    },
    {
      name: "Action",
      cell: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            onClick={() => {
              setShowDelete(true);
              setIdDelete(row.id);
            }}
          >
            <DeleteForever />
          </Button>
          <Button onClick={() => setMurkRead(row.id, row.isRead)}>
            {row.isRead ? <MarkEmailRead /> : <Markunread />}
          </Button>
          <Button
            onClick={() => {
              detailMessage(row.id);
              setShow(true);
            }}
          >
            <ReadMore />
          </Button>
        </Box>
      ),
    },
  ];

  const getByReading = async (read: any) => {
    await axios
      .get(`${baseURL}/GetCustomerContactByFilter?isRead=${read}`)
      .then((response) => {
        setCustomerData(response.data);
        setRecords(response.data);
      })
      .catch((err) => {
        message.error("Hata! Veriler Yüklenemedi " + err.message);
      });
    if (read === false) {
      const interval = setInterval(() => {
        axios
          .get(`${baseURL}/GetCustomerContactByFilter?isRead=${read}`)
          .then((response) => {
            setCustomerData(response.data);
            setRecords(response.data);
          })
          .catch((err) => {
            message.error("Hata! Veriler Yüklenemedi " + err.message);
          });
      }, 1000);

      //Clearing the interval
      return () => clearInterval(interval);
    }
  };
  const setMurkRead = async (id: string, reading: boolean) => {
    reading = !reading;
    await axios
      .put(`${baseURL}/UpdateCustomerContactReading`, { id, reading })
      .then((response) => {
        message.success("İşlem Başarılı");
      })
      .catch((err) => {
        message.error("Hata! İşlem Başarısız " + err.message);
      });
    const newData = customerDatas
      ? customerDatas.map((row) => {
          if (row.id === id) {
            const updated = {
              ...row,
              isRead: !row.isRead,
            };
            return updated;
          }
          return row;
        })
      : null;

    const newData1 = newData?.filter((row) => row.isRead !== reading);

    setCustomerData(newData1);
    setRecords(newData1);
  };

  const [records, setRecords] = useState(customerDatas);
  const handlefilter = (event: any) => {
    const newData = customerDatas
      ? customerDatas.filter((row) => {
          return (row.phoneNumber || row.fullName || row.customerMessage)
            .toLocaleLowerCase()
            .includes(event.target.value.toLowerCase());
        })
      : null;
    setRecords(newData);
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            left: "0",
            top: "0",
            zIndex: "100",
            backgroundColor: "white",
          }}
        >
          <Flex align="center" gap="middle">
            <Spin size="large" />
          </Flex>
        </Box>
      ) : (
        <>
          <Box
            className="dataTable"
            sx={{
              margin: "30px",
            }}
          >
            <Box
              sx={{
                display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
                alignItems: "center",
                justifyContent: "center",
                gap: "15px",
              }}
            >
              <Box>
                <TextField
                  label="Okunmadı"
                  onChange={(e) => {
                    getByReading(e.target.value);
                  }}
                  sx={{ width: "20ch", m: 1 }}
                  select
                >
                  <MenuItem selected key="false" value="false">
                    Okunmadı
                  </MenuItem>
                  <MenuItem key="true" value="true">
                    Okundu
                  </MenuItem>
                </TextField>
              </Box>
              <Box>
                <TextField
                  type="text"
                  onChange={handlefilter}
                  label="Ara..."
                  sx={{ m: 1 }}
                ></TextField>
              </Box>
            </Box>

            {records ? (
              <DataTable
                columns={columns}
                data={records}
                responsive
                highlightOnHover
                dense
                persistTableHead
                pointerOnHover
                striped
                title="Kullanici Mesajları Listesi"
                fixedHeader
                pagination
              />
            ) : null}

            {show ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "100",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflowY: "auto",
                    overflowX: "hidden",
                    borderRadius: "12px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    border: "1px solid #888",
                    height: "50%",
                    width: "40%",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  <Box bgcolor="white" sx={{ position: "sticky", margin: "0" }}>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <CloseRounded />
                    </Button>
                  </Box>
                  <ShowMore />
                </Box>
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflowY: "auto",
                    overflowX: "hidden",
                    borderRadius: "12px",
                    paddingRight: "20px",
                    paddingLeft: "20px",
                    border: "1px solid #888",
                    height: "70%",
                    width: "90%",
                    display: { xs: "block", sm: "block", md: "none" },
                  }}
                >
                  <Box bgcolor="white" sx={{ position: "sticky", margin: "0" }}>
                    <Button
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <CloseRounded />
                    </Button>
                  </Box>
                  <ShowMore />
                </Box>
              </Box>
            ) : null}
            {isShowDeleteModal ? (
              <Box
                sx={{
                  display: "block",
                  position: "fixed",
                  zIndex: "100",
                  paddingTop: "100px",
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                  overflow: "auto",

                  backgroundColor: "rgba(0,0,0,0.4)",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "40%",
                    display: { xs: "none", sm: "none", md: "flex" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>

                <Box
                  sx={{
                    backgroundColor: "#fefefe",
                    margin: "auto",
                    overflow: "auto",
                    paddingTop: "10px",
                    paddingRight: "20px",
                    border: "1px solid #888",
                    height: "40%",
                    width: "90%",
                    display: { xs: "flex", sm: "flex", md: "none" },
                    flexDirection: "column",
                    alignItems: "space-around",
                    borderRadius: "15px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                  >
                    <CloseRounded />
                  </Button>
                  <ShowDelete />
                </Box>
              </Box>
            ) : null}
          </Box>
        </>
      )}
    </>
  );
};

export default CustomerContactView;
