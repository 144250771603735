import { Button, CardMedia, Fade, Menu, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import axios from "axios";
import Avatar from "../../../assets/images/profile-avatar.jpg";
import { message } from "antd";
export type getAllUser = {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  roleId: string;
  imageUrl: string;
  createdAt: string;
  action: any;
};
const TransitionMenu = () => {
  const baseURL = "/User";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/get/`;
  const [userDatas, setUserData] = useState<getAllUser | null>();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userId, setId] = useState("");
  useEffect(() => {
    async function getApi() {
      var id = Cookie.get("userId");
      id ? setId(id) : setId("");
      await axios
        .get(`${baseURL}/GetUserById?Id=${id}`)
        .then((response) => {
          setUserData(response.data);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    Cookie.remove("token");
    navigate("/login");
  };
  return (
    <div style={{ marginRight: "20px" }}>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {userDatas?.imageUrl ? (
          <CardMedia
            component="img"
            src={`${imageBaseUrl}${userDatas?.imageUrl}`}
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            loading="lazy"
          />
        ) : (
          <CardMedia
            component="img"
            src={Avatar}
            style={{ width: "60px", height: "60px", borderRadius: "50%" }}
            loading="lazy"
          />
        )}
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Link to={`/profile/${userId}`}>
          <MenuItem onClick={handleClose} color="black">
            Profil
          </MenuItem>
        </Link>
        <MenuItem
          onClick={() => {
            logout();
            handleClose();
          }}
          style={{ marginRight: "15px" }}
        >
          Çıkış yap
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TransitionMenu;
