import React from "react";
import AdminMain from "../../templates/admin/AdminMain";

const AdminMainPage = () => {
  return (
    <div>
      <AdminMain />
    </div>
  );
};

export default AdminMainPage;
