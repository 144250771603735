import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { CloudUpload, Save, Delete, CloseRounded } from "@mui/icons-material";
import { Button, CardMedia, Typography } from "@mui/material";
import Avatar from "../../../../../assets/images/upload-image-area.png";
import axios from "axios";
import { Alert, message } from "antd";

type props = {
  id: any;
};
export default function UpdateCategory(prop: props) {
  const baseURL = "/Category";
  const imageBaseUrl = `${axios.defaults.baseURL}/Image/`;
  const [name, setName] = useState("");
  const [imageUrl, setImage] = useState("");
  const [imageFile, setFile] = useState(null);
  const { id } = prop;
  useEffect(() => {
    async function getApi() {
      await axios
        .get(`${baseURL}/GetCategoryById?Id=${id}`)
        .then((response) => {
          setName(response.data.name);
          setImage(response.data.imageUrl);
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    getApi();
  }, []);
  const [isShowDeleteModal, setShowDelete] = useState(false);
  const ShowDelete = () => {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ marginTop: "30px" }}>
            <Typography variant="h6" textAlign="center">
              Silmek istediğinizden emin misiniz?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "250px",
              marginTop: "40px",
            }}
          >
            <Button onClick={handleImageDelete}>Evet</Button>
            <Button onClick={() => setShowDelete(false)}>Hayır</Button>
          </Box>
        </Box>
      </>
    );
  };
  const [errorCategory, setErrorCategory] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const updateCategory = () => {
    setErrorCategory("");
    setErrorDb("");
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", name);
    formData.append("imageUrl", imageUrl);
    if (imageFile != null) formData.append("imageFile", imageFile);
    axios
      .put(`${baseURL}/UpdateCategory`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorCategory(error.response.data.errors.Name);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
    setFile(null);
  };
  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
    setImage("");
  };
  const handleImageDelete = async () => {
    await axios
      .delete(`${imageBaseUrl}delete/${imageUrl}`)
      .then((response) => {
        message.success("Silme İşlemi Başarılı");
      })
      .catch((err) => {
        message.error("Hata! Silme İşlemi Başarısız " + err.message);
      });
    setImage("");
    setFile(null);
    updateCategory();
    setShowDelete(false);
  };
  return (
    <Box>
      <Box
        sx={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          display: { xs: "block", md: "block", lg: "flex", xl: "flex" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          {imageUrl ? (
            <Box>
              <Button onClick={() => setShowDelete(true)}>
                <Delete />
              </Button>
            </Box>
          ) : null}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "250px",
              height: "280px",
            }}
          >
            {imageUrl ? (
              <CardMedia
                component="img"
                loading="lazy"
                src={`${imageBaseUrl}get/${imageUrl}`}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            ) : (
              <CardMedia
                component="img"
                loading="lazy"
                src={imageFile ? URL.createObjectURL(imageFile) : Avatar}
                style={{ width: "100%", height: "100%", borderRadius: "5%" }}
              />
            )}
            <Button
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              sx={{ m: 1 }}
              startIcon={<CloudUpload />}
            >
              Fotoğraf
              <TextField
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Button>
          </Box>
        </Box>
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            Bölüm Adını Değiştiriniz
          </Typography>
          {errorDb ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorDb}`}
              closable
            />
          ) : null}
          {errorCategory ? (
            <Alert
              type="error"
              message="Hata"
              description={`${errorCategory}`}
              closable
            />
          ) : null}
          <TextField
            label="Bölüm adı"
            id="outlined-start-adornment"
            onChange={(e) => setName(e.target.value)}
            value={name}
            sx={{ m: 1 }}
          />

          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              onClick={updateCategory}
              sx={{ m: 1 }}
            >
              Kaydet
            </Button>
          </Box>
        </div>
      </Box>
      {isShowDeleteModal ? (
        <Box
          sx={{
            display: "block",
            position: "fixed",
            zIndex: "100",
            paddingTop: "100px",
            left: "0",
            top: "0",
            width: "100%",
            height: "100%",
            overflow: "auto",

            backgroundColor: "rgba(0,0,0,0.4)",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "40%",
              display: { xs: "none", sm: "none", md: "flex" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>

          <Box
            sx={{
              backgroundColor: "#fefefe",
              margin: "auto",
              overflow: "auto",
              paddingTop: "10px",
              paddingRight: "20px",
              border: "1px solid #888",
              height: "40%",
              width: "90%",
              display: { xs: "flex", sm: "flex", md: "none" },
              flexDirection: "column",
              alignItems: "space-around",
              borderRadius: "15px",
            }}
          >
            <Button
              onClick={() => {
                setShowDelete(false);
              }}
            >
              <CloseRounded />
            </Button>
            <ShowDelete />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}
