import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  DialogProps,
  Grid,
  IconButton,
  Skeleton,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import { FireFilled } from "@ant-design/icons";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";

type list = {
  isList: Boolean | undefined;
  isPopuler: Boolean | undefined;
};

export type menuTypes = {
  id: string;
  name: string;
  imageUrl: string;
  orderNumber: number;
};
export type visitTypes = {
  id: string;
};
export type getProduct = {
  id: string;
  title: string;
  description: string;
  price: string;
  discountedPrice: string;
  imageUrl: string;
  categoryId: string;
  isActive: boolean;
  isStarred: boolean;
  isGhost: boolean;
};
const MenuSection = (prop: list) => {
  const baseURL = "/Category";
  const baseVisitURL = "/Visit";
  const baseProductURL = "/Product";
  const baseImageURL = `${axios.defaults.baseURL}/Image/get`;

  const [menus, setMenu] = useState<menuTypes[] | null>(null);
  const [products, setProducts] = useState<getProduct[] | null>(null);
  const [allProducts, setAllProducts] = useState<getProduct[] | null>(null);
  const [visitId, setVisitId] = useState<visitTypes | null>(null);
  const [menuLoading, setMenuLoading] = useState(true);
  const [allProductLoading, setAllProductLoading] = useState(true);
  const [ProductLoading, setProductLoading] = useState(true);

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllCategory`)
        .then((response) => {
          setMenuLoading(false);
          setMenu(response.data);
        })
        .catch((err) => {});
      await axios
        .get(`${baseVisitURL}/GetVisit`)
        .then((response) => {
          response.data.map((value: any) => setVisitId(value));
        })
        .catch((err) => {});
      await axios
        .get(`${baseProductURL}/AdminGetAllProduct`)
        .then((response) => {
          setAllProductLoading(false);
          setAllProducts(response.data);
        })
        .catch((err) => {});
    }
    api();
  }, []);

  useEffect(() => {
    async function api() {
      await axios
        .get(
          `${baseProductURL}/GetProductByCategoryId?Id=${
            menus
              ? menus.sort((a, b) =>
                  a.orderNumber > b.orderNumber ? 1 : -1
                )[0].id
              : null
          }`
        )
        .then((response) => {
          setProductLoading(false);
          setProducts(response.data);
        })
        .catch((err) => {});
    }
    api();
  }, [menus]);
  const getProduct = async (id: string) => {
    await axios
      .get(`${baseProductURL}/GetProductByCategoryId?Id=${id}`)
      .then((response) => {
        setProductLoading(false);
        setProducts(response.data);
      })
      .catch((err) => {});
  };
  const updateVisit = async () => {
    var id = visitId?.id;
    await axios
      .put(`${baseVisitURL}/UpdateVisit?id=${id}`)
      .then((response) => {})
      .catch((err) => {});
  };
  const { isList, isPopuler } = prop;
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 13,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 10,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const responsivePopuler = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const [ProductModalValues, setProductModalValues] =
    useState<getProduct | null>(null);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const productList = () => {
    return (
      <>
        {ProductLoading ? (
          <>
            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
                display: "flex",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={141}
                height={160}
                animation="wave"
                style={{ margin: "7px", borderRadius: "10px" }}
              />
              <CardContent>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={250}
                  height={60}
                />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
              </CardContent>
            </Card>

            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
                display: "flex",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={141}
                height={160}
                animation="wave"
                style={{ margin: "7px", borderRadius: "10px" }}
              />
              <CardContent>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={250}
                  height={60}
                />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
              </CardContent>
            </Card>

            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
                display: "flex",
              }}
            >
              <Skeleton
                variant="rectangular"
                width={141}
                height={160}
                animation="wave"
                style={{ margin: "7px", borderRadius: "10px" }}
              />
              <CardContent>
                <Skeleton
                  variant="text"
                  animation="wave"
                  width={250}
                  height={60}
                />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
                <Skeleton variant="text" animation="wave" width={250} />
              </CardContent>
            </Card>
          </>
        ) : (
          products?.map((value, index) => {
            return (
              <div
                onClick={() => {
                  updateVisit();
                  handleClickOpen("paper");
                  setProductModalValues(value);
                }}
              >
                <Box
                  bgcolor={value.isGhost ? "#ddd" : "#fcfcfc"}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "#900C3F",
                    borderRadius: "8px",
                    gap: "30px",
                    height: "150px",
                    cursor: "pointer",
                    transition: "0.2s",
                    zIndex: "1",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 1px 2px, rgb(250, 241, 228, 0 ) 0px 0px 1px 2px inset",
                    "&:hover": {
                      background: "rgba(255, 87, 51, 0.2)",
                      scale: "1.05",
                      color: "#C70039",
                    },
                  }}
                >
                  <Box
                    sx={{
                      marginLeft: "15px",
                      border: "none",
                      zIndex: "2",
                      marginTop: "10px",
                      marginBottom: "10px",
                      position: "relative",
                    }}
                  >
                    {value.imageUrl ? (
                      <CardMedia
                        component="img"
                        loading="lazy"
                        src={`${baseImageURL}/${value.imageUrl}`}
                        style={{
                          width: "130px",
                          height: "130px",
                          border: "none",
                          borderRadius: "12px",
                        }}
                      />
                    ) : null}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      whiteWrap: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    <Box>
                      <Typography fontWeight="550">
                        {value.title?.length > 22
                          ? value.title?.substring(0, 22) + "..."
                          : value.title}
                      </Typography>
                      <Typography fontWeight="350" variant="body2">
                        {value.description?.length > 28
                          ? value.description?.substring(0, 28) + "..."
                          : value.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      {value.discountedPrice ? (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontWeight="550" fontSize="20px">
                            {value.discountedPrice} TL
                          </Typography>
                          <Typography
                            fontWeight="500"
                            sx={{
                              position: "relative",
                              textDecoration: "line-through",
                            }}
                          >
                            {value.price} TL
                          </Typography>
                        </Box>
                      ) : (
                        <Typography fontWeight="550">
                          {value.price} TL
                        </Typography>
                      )}
                      {value.isGhost ? (
                        <Typography color="#A4ABB6">Servis Yok</Typography>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </div>
            );
          })
        )}
      </>
    );
  };

  const productModule = () => {
    return (
      <>
        {ProductLoading ? (
          <>
            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
              }}
            >
              <Skeleton variant="rectangular" height={160} animation="wave" />
              <CardContent>
                <Skeleton variant="text" animation="wave" height={60} />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </CardContent>
            </Card>
            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
              }}
            >
              <Skeleton variant="rectangular" height={160} animation="wave" />
              <CardContent>
                <Skeleton variant="text" animation="wave" height={60} />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </CardContent>
            </Card>
            <Card
              style={{
                marginBottom: "30px",
                backgroundColor: "#fffffd",
              }}
            >
              <Skeleton variant="rectangular" height={160} animation="wave" />
              <CardContent>
                <Skeleton variant="text" animation="wave" height={60} />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
                <Skeleton variant="text" animation="wave" />
              </CardContent>
            </Card>{" "}
          </>
        ) : (
          products?.map((value, index) => {
            return (
              <div
                onClick={() => {
                  updateVisit();
                  handleClickOpen("paper");
                  setProductModalValues(value);
                }}
              >
                <Box
                  bgcolor={value.isGhost ? "#ddd" : "#fcfcfc"}
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "8px",
                    gap: "20px",
                    cursor: "pointer",
                    transition: "0.2s",
                    color: "#900C3F",
                    height: "450px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 1px 2px, rgb(250, 241, 228, 0 ) 0px 0px 1px 2px inset",
                    "&:hover": {
                      background: "rgba(255, 87, 51, 0.2)",
                      scale: "1.05",
                      color: "#C70039",
                    },
                  }}
                >
                  <CardActionArea
                    sx={{ width: "100%", margin: "0", position: "relative" }}
                  >
                    {value.imageUrl ? (
                      <CardMedia
                        component="img"
                        loading="lazy"
                        src={`${baseImageURL}/${value.imageUrl}`}
                        style={{
                          height: "270px",
                        }}
                      />
                    ) : null}
                  </CardActionArea>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      width: "100%",
                      gap: "20px",
                      marginTop: "10px",
                      marginBottom: "20px",
                      paddingLeft: "20px",
                      whiteWrap: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    <Box>
                      <Typography fontWeight="600">
                        {value.title?.length > 40
                          ? value.title?.substring(0, 40) + "..."
                          : value.title}
                      </Typography>
                      <Typography variant="body2" fontWeight="400">
                        {value.description?.length > 49
                          ? value.description?.substring(0, 49) + "..."
                          : value.description}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      {value.discountedPrice ? (
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                          }}
                        >
                          <Typography fontWeight="550" fontSize="20px">
                            {value.discountedPrice} TL
                          </Typography>
                          <Typography
                            fontWeight="500"
                            sx={{
                              position: "relative",
                              textDecoration: "line-through",
                            }}
                          >
                            {value.price} TL
                          </Typography>
                        </Box>
                      ) : (
                        <Typography fontWeight="550">
                          {value.price} TL
                        </Typography>
                      )}
                      {value.isGhost ? (
                        <Typography color="#A4ABB6">Servis Yok</Typography>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              </div>
            );
          })
        )}
      </>
    );
  };

  const populerSection = () => {
    return (
      <Box>
        <Typography
          fontSize="30px"
          fontWeight="600"
          fontFamily="Livvic, sans-serif"
          color="#843619"
          paddingLeft="10px"
        >
          <FireFilled /> Popüler
        </Typography>
        <Typography fontFamily="Livvic, sans-serif" paddingLeft="10px">
          En çok tercih edilen ürünler
        </Typography>
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            marginTop: "20px",
            backgroundColor: "transparent",
          }}
        >
          {allProductLoading ? (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={3}>
                <Card
                  style={{
                    marginBottom: "30px",
                    backgroundColor: "#fffffd",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    height={120}
                    animation="wave"
                  />
                  <CardContent>
                    <Skeleton variant="text" animation="wave" height={60} />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card
                  style={{
                    marginBottom: "30px",
                    backgroundColor: "#fffffd",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    height={120}
                    animation="wave"
                  />
                  <CardContent>
                    <Skeleton variant="text" animation="wave" height={60} />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : allProducts ? (
            <Carousel responsive={responsivePopuler} customTransition="all .5">
              {allProducts.map((value, index) => {
                return value.isStarred && !value.isGhost ? (
                  <Box
                    onClick={() => {
                      updateVisit();
                      handleClickOpen("paper");
                      setProductModalValues(value);
                    }}
                  >
                    <Box
                      bgcolor={value.isGhost ? "#ddd" : "transparent"}
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        gap: "10px",
                        cursor: "pointer",
                        transition: "0.2s",
                        color: "#900C3F",

                        "&:hover": {
                          background: "rgba(225, 155, 155, 0.2)",
                          scale: "1.02",
                          color: "#FA7070",
                        },
                      }}
                    >
                      <Box sx={{ width: "98%" }}>
                        {value.imageUrl ? (
                          <CardMedia
                            component="img"
                            loading="lazy"
                            src={`${baseImageURL}/${value.imageUrl}`}
                            style={{
                              height: "170px",
                            }}
                          />
                        ) : null}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                          width: "100%",
                          gap: "10px",
                          marginTop: "10px",
                          marginBottom: "20px",
                          marginLeft: "25px",
                        }}
                      >
                        <Box>
                          <Typography fontWeight="600">
                            {value.title?.length > 15
                              ? value.title?.substring(0, 15) + "..."
                              : value.title}
                          </Typography>
                          <Typography variant="body2" fontWeight="400">
                            {value.description?.length > 15
                              ? value.description?.substring(0, 15) + "..."
                              : value.description}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                            alignItems: "center",
                          }}
                        >
                          {value.discountedPrice ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "15px",
                                alignItems: "center",
                              }}
                            >
                              <Typography fontWeight="550" fontSize="20px">
                                {value.discountedPrice} TL
                              </Typography>
                              <Typography
                                fontWeight="500"
                                sx={{
                                  position: "relative",
                                  textDecoration: "line-through",
                                }}
                              >
                                {value.price} TL
                              </Typography>
                            </Box>
                          ) : (
                            <Typography fontWeight="550">
                              {value.price} TL
                            </Typography>
                          )}
                          {value.isGhost ? (
                            <Typography color="#A4ABB6">
                              Şuan için servis dışı
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : null;
              })}
            </Carousel>
          ) : null}
        </Box>
      </Box>
    );
  };
  const [underLineVisibility, setVisibility] = useState(0);
  return (
    <>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullScreen={fullScreen}
          maxWidth="sm"
          scroll={scroll}
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              backgroundColor: "white",
              top: 8,
              color: (theme) => theme.palette.grey[500],
              "&:hover": {
                backgroundColor: "rgba(250,112,112,0.5)",
              },
            }}
          >
            <CloseIcon style={{ color: "#C70039" }} />
          </IconButton>
          {ProductModalValues ? (
            <DialogContent
              dividers={scroll === "paper"}
              sx={{ p: 0, height: "550px" }}
            >
              <Card sx={{ boxShadow: "0" }}>
                {ProductModalValues.imageUrl ? (
                  <CardMedia
                    component="img"
                    image={`${baseImageURL}/${ProductModalValues.imageUrl}`}
                    sx={{
                      minWidth: {
                        md: "550px",
                      },
                      maxHeight: "250px",
                    }}
                  />
                ) : null}
                <CardContent>
                  <Box>
                    <Typography fontWeight="600">
                      {ProductModalValues.title}
                    </Typography>
                    <Typography color="#A9A9A9" fontWeight="400">
                      {ProductModalValues.description}
                    </Typography>
                  </Box>
                  <Typography
                    component="div"
                    color="text.primary"
                    height="25px"
                  ></Typography>
                  <Box
                    sx={{ display: "flex", gap: "15px", alignItems: "center" }}
                  >
                    {ProductModalValues.discountedPrice ? (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                      >
                        <Typography fontWeight="550" fontSize="20px">
                          {ProductModalValues.discountedPrice} TL
                        </Typography>
                        <Typography
                          fontWeight="500"
                          sx={{
                            position: "relative",
                            textDecoration: "line-through",
                          }}
                        >
                          {ProductModalValues.price} TL
                        </Typography>
                      </Box>
                    ) : (
                      <Typography fontWeight="550">
                        {ProductModalValues.price} TL
                      </Typography>
                    )}
                    {ProductModalValues.isGhost ? (
                      <Typography color="#A4ABB6">
                        Şuan için servis dışı
                      </Typography>
                    ) : null}
                  </Box>
                </CardContent>
              </Card>
            </DialogContent>
          ) : null}
        </Dialog>
      )}

      <Box>
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            marginTop: "20px",
            marginLeft: "15px",
            marginRight: "15px",
            backgroundColor: "#A20A0A",
            borderRadius: "5px",
          }}
        >
          {menuLoading ? (
            <Card
              style={{
                backgroundColor: "#fffffd",
                width: "220px",
              }}
            >
              <Skeleton variant="rectangular" height={80} animation="wave" />
              <CardContent>
                <Skeleton variant="text" animation="wave" />
              </CardContent>
            </Card>
          ) : menus ? (
            <Carousel responsive={responsive} customTransition="all .5">
              {menus
                .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
                .map((value, index) => {
                  return (
                    <Box
                      key={index}
                      sx={
                        underLineVisibility === index
                          ? {
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "15px",
                              color: "#FFB100",
                              backgroundColor: "rgba(128, 0, 32,0.4)",
                              borderRadius: "10px",
                              transition: "0.2s",
                              boxShadow:
                                "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
                              marginTop: "15px",
                            }
                          : {
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              marginBottom: "15px",
                              marginTop: "5px",
                              color: "#FBC252",
                              transition: "0.2s",
                              borderRadius: "10px",
                            }
                      }
                    >
                      <Box
                        sx={{
                          transition: "0.3s",
                          cursor: "pointer",
                          "&:hover": {
                            scale: "1.07",
                          },
                        }}
                      >
                        {value.imageUrl ? (
                          <CardMedia
                            component="img"
                            onClick={() => {
                              setVisibility(index);
                              getProduct(value.id);
                            }}
                            loading="lazy"
                            src={`${baseImageURL}/${value.imageUrl}`}
                            style={{
                              width: "100px",
                              height: "100px",
                              borderRadius: "10px",
                            }}
                          />
                        ) : null}
                      </Box>
                      <Box>
                        {" "}
                        <Typography
                          onClick={() => getProduct(value.id)}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          {value.name}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Carousel>
          ) : null}
        </Box>
        <Box
          sx={{
            marginTop: "35px",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          {isPopuler ? populerSection() : null}
        </Box>
        <Box>
          <Box
            sx={{
              marginLeft: "80px",
              marginRight: "80px",
              marginTop: "40px",
              marginBottom: "20px",
              whiteSpace: "pre-wrap",
              gridTemplateColumns: "1fr 1fr 1fr",
              display: { xs: "none", sm: "none", md: "none", lg: "grid" },
              gap: "25px",
              color: "#1b2625",
            }}
          >
            {isList ? productList() : productModule()}
            <Box></Box>
          </Box>
          <Box
            sx={{
              marginLeft: "80px",
              marginRight: "80px",
              marginTop: "40px",
              marginBottom: "20px",
              whiteSpace: "pre-wrap",
              gridTemplateColumns: "1fr 1fr",
              display: { xs: "none", sm: "none", md: "grid", lg: "none" },
              gap: "25px",
              color: "#1b2625",
            }}
          >
            {isList ? productList() : productModule()}
          </Box>
          <Box
            sx={{
              marginLeft: "5px",
              marginRight: "5px",
              marginTop: "40px",
              marginBottom: "20px",
              whiteSpace: "pre-wrap",
              gridTemplateColumns: "1fr",
              display: { xs: "grid", sm: "grid", md: "none", lg: "none" },
              gap: "25px",
              color: "#1b2625",
            }}
          >
            {isList ? productList() : productModule()}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MenuSection;
