import { Box, Button, TextField } from "@mui/material";
import { Alert, message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ChangePassword = () => {
  const baseURL = "/User";

  const [newPassword, setNewPassword] = useState("");
  const { email, otp } = useParams();
  const [errorPass, setErrorPass] = useState("");
  const [errorDb, setErrorDb] = useState("");
  const navigate = useNavigate();
  const sendNewPassword = async () => {
    setErrorPass("");
    setErrorDb("");
    var otpCode = otp;
    await axios
      .put(`${baseURL}/ResetPassword`, {
        email,
        newPassword,
        otpCode,
      })
      .then((response) => {
        message.success("İşlem Başarılı ");
        if (response.data == true) navigate(`/login`);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          setErrorPass(error.response.data.errors.NewPassword);
        }
        if (error) setErrorDb(error.response.data.error);
        message.error("Hata! İşlem Başarısız " + error.message);
      });
  };
  return (
    <div>
      <Box
        sx={{
          marginTop: "55px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
        }}
      >
        <Box
          sx={{
            width: "40%",

            display: { xs: "none", sm: "none", md: "none", lg: "flex" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box></Box>
            {errorPass ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorPass}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                size="small"
                sx={{ m: 1, width: "35ch" }}
                type="password"
                label="Yeni Şifrenizi Oluşturun"
                onChange={(e) => setNewPassword(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendNewPassword}>
                Gonder
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "60%",
            flexDirection: "column",
            display: { xs: "none", sm: "none", md: "flex", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box></Box>
            {errorPass ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorPass}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                size="small"
                sx={{ m: 1, width: "35ch" }}
                type="password"
                label="Yeni sifrenizi olusturun"
                onChange={(e) => setNewPassword(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendNewPassword}>
                Gönder
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: "95%",
            flexDirection: "column",
            display: { xs: "column", sm: "column", md: "none", lg: "none" },
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box></Box>
            {errorPass ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorPass}`}
                closable
              />
            ) : null}
            <Box>
              <TextField
                size="small"
                sx={{ m: 1, width: "35ch" }}
                type="password"
                label="Yeni sifrenizi olusturun"
                onChange={(e) => setNewPassword(e.target.value)}
              ></TextField>
            </Box>
            {errorDb ? (
              <Alert
                type="error"
                message="Hata"
                description={`${errorDb}`}
                closable
              />
            ) : null}
            <Box>
              <Button sx={{ m: 1 }} onClick={sendNewPassword}>
                Gonder
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ChangePassword;
