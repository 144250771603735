import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Save } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { message } from "antd";

export default function UpdateBusinessContact() {
  const baseURL = "/BusinessContact";
  const [id, setId] = useState("");
  const [displayName, setdisplayName] = useState("");
  const [appPassword, setappPassword] = useState("");
  const [email, setMail] = useState("");
  const [phonNumber, setphonNumber] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [fullAddress, setfullAddress] = useState("");

  useEffect(() => {
    async function api() {
      await axios
        .get(`${baseURL}/GetAllBusinessContact`)
        .then((response) => {
          response.data.map((value: any) => {
            setId(value.id);
            setdisplayName(value.displayName);
            setappPassword(value.appPassword);
            setMail(value.email);
            setphonNumber(value.phonNumber);
            setwhatsapp(value.whatsapp);
            setfullAddress(value.fullAddress);
          });
        })
        .catch((err) => {
          message.error("Hata! Veriler Yüklenemedi " + err.message);
        });
    }
    api();
  }, []);
  const updateUser = async () => {
    await axios
      .put(`${baseURL}/UpdateBusinessContact`, {
        id,
        displayName,
        email,
        appPassword,
        phonNumber,
        whatsapp,
        fullAddress,
      })
      .then((response) => {
        message.success("Güncelleme İşlemi Başarılı");
      })
      .catch((error) => {
        message.error("Hata! Güncelleme İşlemi Başarısız " + error.message);
      });
  };
  return (
    <Box>
      <Box
        sx={{
          alignItems: "center",
          gridTemplateColumns: "1fr 1fr 1fr",
          display: { xs: "block", md: "block", lg: "grid", xl: "grid" },
        }}
      >
        <Box></Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Typography
            variant="h6"
            fontWeight="700"
            fontSize="25px"
            component="div"
            color="black"
            textAlign="center"
            sx={{
              alignItems: "Start",
            }}
          >
            İletişim Ve E-posta
          </Typography>
          <TextField
            label="Gmail Başlığı"
            onChange={(e) => setdisplayName(e.target.value)}
            value={displayName}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Eposta adresi"
            onChange={(e) => setMail(e.target.value)}
            value={email}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Uygulama Şifresi"
            onChange={(e) => setappPassword(e.target.value)}
            value={appPassword}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
          />
          <TextField
            label="Telefon"
            onChange={(e) => setphonNumber(e.target.value)}
            value={phonNumber}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
            type="tel"
            inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
          />
          <TextField
            label="Whatsapp"
            onChange={(e) => setwhatsapp(e.target.value)}
            value={whatsapp}
            id="outlined-start-adornment"
            sx={{ m: 1 }}
            type="tel"
            inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
          />
          <label>İşletme Adresi</label>
          <TextArea
            placeholder="İşletme Adresi"
            onChange={(e) => setfullAddress(e.target.value)}
            style={{ height: "120px" }}
            value={fullAddress}
          />
          <Box>
            <Button
              type="submit"
              startIcon={<Save />}
              variant="contained"
              onClick={updateUser}
              sx={{ m: 1 }}
            >
              Kaydet
            </Button>
          </Box>
        </Box>
        <Box></Box>
      </Box>
    </Box>
  );
}
